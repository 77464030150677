enum Features {
    Compliments = 'compliments',
    Incognito_mode = 'incognito-mode',
    Opening_moves = 'opening-moves',
    Snooze_mode = 'snooze-mode',
    Superswipe = 'superswipe',
    Travel_mode = 'travel-mode',
    Video_chat = 'video-chat',
    Web = 'web',
}

const rootFeatureRouteFolder = '/features/';
const langFeatureRouteFolder = '/[lang]/features/';

enum FeatureRoutes {
    Compliments = rootFeatureRouteFolder + Features.Compliments,
    Incognito_mode = rootFeatureRouteFolder + Features.Incognito_mode,
    Opening_moves = rootFeatureRouteFolder + Features.Opening_moves,
    Snooze_mode = rootFeatureRouteFolder + Features.Snooze_mode,
    Superswipe = rootFeatureRouteFolder + Features.Superswipe,
    Travel_mode = rootFeatureRouteFolder + Features.Travel_mode,
    Video_chat = rootFeatureRouteFolder + Features.Video_chat,
    Web = rootFeatureRouteFolder + Features.Web,
    LocalisedCompliments = langFeatureRouteFolder + Features.Compliments,
    LocalisedIncognito_mode = langFeatureRouteFolder + Features.Incognito_mode,
    LocalisedOpening_moves = langFeatureRouteFolder + Features.Opening_moves,
    LocalisedSnooze_mode = langFeatureRouteFolder + Features.Snooze_mode,
    LocalisedSuperswipe = langFeatureRouteFolder + Features.Superswipe,
    LocalisedTravel_mode = langFeatureRouteFolder + Features.Travel_mode,
    LocalisedVideo_chat = langFeatureRouteFolder + Features.Video_chat,
    LocalisedWeb = langFeatureRouteFolder + Features.Web,
}

export { Features, FeatureRoutes };

const FEATURE_COMPLIMENTS_AMOUNT_NUM = 3;
const FEATURE_SUPERSWIPE_AMOUNT_NUM = 10;

export { FEATURE_COMPLIMENTS_AMOUNT_NUM, FEATURE_SUPERSWIPE_AMOUNT_NUM };
