/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "返回",
      "在 App Store 下載",
      "在 Google  Play 下載",
      "圖文",
      "簡報 {{number}}",
      "下一張簡報",
      "上一張簡報",
      "簡報",
      "簡報 {{number}}，共 {{total}} 頁",
      "不可販售或分享我的個人資料",
      "不許售賣或分享我的個人信息",
      "在 Bumble上送出 Compliments，表達你喜歡他們，並在配對之前吸引他們的注意力。現在就試試看",
      "Compliments | 如何傳送 Compliments | Bumble",
      "以自己的步調約會，並且使用隱身模式保有Bumble個人資料的控制權。瞭解如何在Bumble上使用隱身模式，請參閱我們的指南。",
      "隱身模式 | 隱藏個人資料 | Bumble",
      "嘗試使用 Bumble 的 Opening Moves 功能，以全新方式開始對話。引起對方的注意，設定氛圍，讓更快地進入對話。",
      "Bumble 的 Opening Moves | Bumble",
      "需要一點休息或是為自己的時間嗎？了解如何使用 Bumble 的瞌睡模式暫停你的 Bumble 帳戶，按照自己的步伐約會。",
      "瞌睡模式 | 暫停帳戶 | Bumble",
      "在Bumble上看到一位真的很喜歡的人嗎？使用超級滑動表達你的誠意。在他們的個人資料上點選星號開始，或在此處查看更多詳細資訊",
      "Bumble 的超級滑動 | 什麼是超級滑動？ | Bumble",
      "旅行以工作或遊戲為目的？Bumble 的旅行模式可幫助你在新地方建立有趣的新連結，並在旅途中約會。現在就在此處探索旅行模式。",
      "Bumble 的旅行模式 | 在任何地方約會 | Bumble",
      "想知道你在訊息中感受到的連結是否意味著現實中的化學反應？現在在 Bumble 上進行視訊聊天或語音通話，看看是否真的有感覺",
      "視訊聊天 | 撥打電話 | Bumble",
      "體驗Bumble 網頁版的熱情。",
      "Bumble 網站 | Bumble",
      "在此終止合約",
      "Bumble 首頁",
      "選擇選單",
      "打開選單",
      "選擇語言",
      "腳註",
      "Bumble的無障礙功能",
      "Apple 和 Apple 標誌是 Apple Inc. 的商標",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "工作機會",
      "活動",
      "Google Play是 Google LLC 的商標。",
      "指導原則",
      "投資家",
      "管理 Cookie",
      "管理 Cookie",
      "現代奴役法案聲明",
      "資料收集聲明",
      "隱私權政策",
      "不同季節的約會指南",
      "條款",
      "應用",
      "Washington Consumer Health Data Privacy Policy",
      "關於",
      "大使",
      "嗡嗡",
      "結交好友，建立社群",
      "事業網絡和進行職業轉型",
      "尋找戀愛關係、隨心所欲或其他關係",
      "瞭解更多",
      "使用旅行模式全球行動",
      "功能",
      "Compliments™",
      "送出 Compliments 讓自己脫穎而出。",
      "隱身模式",
      "使用隱身模式隱藏檔案，不讓同事、親友或前任看見你。",
      "Opening Moves",
      "使用 <b>Opening Moves</b> 讓對方開啟對話，你也不用想破腦袋。",
      "瞌睡模式",
      "透過瞌睡模式先暫停一下，準備好約會時再回歸。",
      "超級滑動",
      "對方檔案看起來還不錯？透過超級滑動讓對方知道。",
      "旅行模式",
      "透過旅行模式在全球配對。",
      "視訊聊天",
      "通過視訊和語音通話，嘗試判斷是否準備好面對面見面。",
      "網頁應用程式",
      "從瀏覽器直接體驗 Bumble 體驗。",
      "商店",
      "主要",
      "直接前往主內容",
      "Bumble 標誌",
      "想認識對方嗎？在 Bumble 使用視訊聊天。",
      "簡單地點選您的聊天中的視訊圖示以開始視訊通話，無需分享任何個人聯絡資料。",
      "如何在Bumble上進行視訊聊天？",
      "無。所有在 Bumble 的通話和聊天都會保護您的個人資料安全。他們在您告訴他們之前，無法獲取您的號碼或任何其他聯繫詳細資料。",
      "我的配對會看到我的電話號碼嗎？",
      "嗯，如果您還沒準備好面對面，聲音通話可以透過聲音進行連接。只需選擇通話圖示即可。",
      "我可以嘗試一下語音通話嗎？",
      "我們喜歡收到好消息通知。但我們知道討論是否會像面對面一樣好很難判斷。這就是視訊和音訊通話的用途。",
      "在Bumble上進行視頻聊",
      "<a href={{video_chat_url}}>視訊聊天和語音通話</a>可以幫助你了解對方，並決定是否要見面。最好的部分？當你透過 Bumble 進行通話時，你不必交換電話號碼，因此你的個人資訊在你準備分享之前都很安全。",
      "用視訊聊天看看你們的化學反應",
      "闖關於線上和線下約會之間的隙縫，並決定是否時候展開相遇。",
      "了解他們更好（及更快）。面對面的對話或電話對話可以揭示很多關於某人的資訊。",
      "你掌握著控制。我們會保持你的聯絡詳細資料私密，讓你可以自己選擇何時分享。",
      "嘗試看看您是否準備好面對面見面",
      "嘗試快速氛圍檢查",
      "無需提供個人資訊即可連接",
      "為什麼您應該使用視訊聊天和通話",
      "準備好開始對話了嗎？您可以從 Bumble 應用程式的聊天中選擇開始語音通話或視訊通話。要開始視訊通話，請點選右上角的灰色視訊相機圖示。您還會看到圖示旁邊的三個點，點選此處，您將看到音訊通話的選項。",
      "如何視訊聊天",
      "準備好體驗 Compliments™",
      "當你傳送一個 Compliment 時，收到它的人會在你在他們的「推薦給你」時看到它。",
      "當你在 Bumble上 讚美某人時會發生什麼事？",
      "如果他們的讚美引起了你的注意，就隨意與他們配對。你會在你的聊天中看到讚美，並且「<a href={{make_the_first_move_url}}>主動出擊</a>」的規則適用。",
      "如何回應讚美？",
      "您可以免費傳送 {{num}} 個[[Compliments:num]]。當您用完時，可以在 Bumble 應用程序中以三十個為限的包裝購買更多。這些中的一個將用於傳送該讚美（將其餘留待您看到另一個喜歡的個人資料時）。請放心，它們不會過期！",
      "我可以發送多少個 Compliments 給他們",
      "好話帶來的好處多多。如果您想表達讚美，但是您的拇指在鍵盤上飄浮，請嘗試以下這些作為靈感：",
      "查看更多例子",
      "「喜歡[artist name]的人都有好品味。」",
      "「微笑傳染性很強！」",
      "「您的個人簡介讓我笑了。不錯！」",
      "Bumble 如何傳送更好的讚美",
      "想要讓對方擁有美好一天嗎？似乎你已準備好要使用 Compliments™ 了！",
      "Bumble 的 Compliments",
      "使用對 Bumble 的讚美，讓潛在的對象知道您喜歡他們的個人資料（甚至可能讓他們臉紅）。您作為 {{service_level_lexeme_name}} 會員所獲得的讚美也是如此。這也是在您還沒有配對之前發送和接收消息的方式。",
      "Compliments 功能的運作方式",
      "當你收到讚美時，它會出現在發送者的個人資料中，在你的「推薦給你」。你還會收到一個通知，告訴你有人喜歡你個人資料的一部分。",
      "查看你收到的 Compliments",
      "你可以透過點選某人個人資料底部的黃色心形圖示來發送讚美。點選的位置很重要，因為你將讚美該特定照片或個人資料的部分！你點選時會開啟一個對話框，供你寫下訊息（請確保為150 個字元！）。",
      "送出讚美",
      "下載Bumble",
      "常見問題與解答",
      "準備好私下逛逛 Bumble 嗎？",
      "啟用隱身模式後，你將不會出現在其他 Bumble 成員中，除非你喜歡他們的個人資料。",
      "隱身模式如何運作？",
      "如果沒有隱身模式，您的個人資料會在Bumble顯示給其他用戶（當您符合他們的喜好，例如年齡和位置）。當您啟用隱身模式時，您僅會在您喜歡他們後才顯示。",
      "誰可以在Bumble的「隱身模式」中看到我？",
      "使用隱身模式並不等於在 Bumble 上<a href={{blocking_on_bumble_url}}>封鎖</a>某人。使用此功能時，如果您喜歡某人（或是在過去與他們對話），他們將能夠看到你的個人資料。",
      "隱身模式就像封鎖某人嗎？",
      "使用 Bumble 的隱身模式控制誰可以查看個人資料",
      "Bumble上的隱身模式",
      "如果您想從交往中縮短一步，或在Bumble上閱覽其他交友者的個人資料而不被他人看到您的個人資料，則可以啟用隱身模式。啟用此功能後，我們將隱藏您的個人資料，除非您先喜歡他們。這允許您控制您要分享的內容，以及誰可以看到您。",
      "切換到隱身模式隱藏檔案",
      "您決定誰可以看到您的相片和個人資訊。",
      "想在個人資料上加入一些個人化內容嗎？您可以隱私地與當前配對分享您想要的內容。",
      "隨意逛逛，不必擔心會遇到前任、同事或是不在你愛情雷達上的人。",
      "完全掌握",
      "選擇您想分享的內容",
      "避免尷尬",
      "為什麼你會愛上隱身模式",
      "隱身模式適用於{{service_level_lexeme_name}}和{{service_level_lexeme_name_two}}成員。註冊後，可以通過導航至你的個人資料分頁，點選右上角的「設定」，然後切換到隱身模式。",
      "升級到{{service_level_lexeme_name}}",
      "如何在Bumble上使用隱身模式",
      "試看看 Bumble 的 Opening Moves",
      "Opening Move 是另一種與你喜歡的人交談的方式。一旦你選擇了要發送的訊息，你可以放鬆，等待回應，並回答真正引起注意的回應。",
      "如何使用 Bumble 的 Opening Moves",
      "針對非二元和同性連結，任何人都可以設定和回應 Opening Move。",
      "可以使用 Bumble Opening Move 與同性約會嗎？",
      "當某人回答你的 Opening Move 時，你會在聊天中看到他們的個人資料出現。女士有 24 小時的回應時間，否則配對過期。然後，你可以回覆她們的回答或開始一個新的對話。",
      "我如何查看有沒有人回應我的 Opening Move？",
      "Opening Move 是我們減少在第一次動作上的壓力的方式，提供一種更簡單、更快的方式來進入好的對話。",
      "Bumble 的 Opening Move",
      "我們很高興你提出了問題。Opening Moves 是一種新的、更容易開始對話的方式。您可以選擇建議的 Opening Moves，例如，「如果在派對上拿到聲音控制器，你最喜歡的歌曲是什麼？「，或撰寫您自己的開場動作。如果您設定了一個，當您出現在對方的配對畫面時，對方可以回答您的問題。",
      "馬上下載",
      "什麼是 Opening Move？",
      "解除一些壓力",
      "什麼最能代表你？",
      "開始更好的對話",
      "Opening Moves 可以幫助…",
      "您可以在與其他交友者配對時發送 Opening Moves。只需前往個人資料中的 Opening Moves，選擇一個預先撰寫的問題，或自己創意地撰寫一個。您可以隨時更改您的 Opening Moves。",
      "試看看 Opening Moves",
      "如何使用 Bumble 的 Opening Moves",
      "<a href={{see_more_url}}>查看更多</a>",
      "想要休息一下嗎？嘗試使用瞌睡模式",
      "是的，你可以隨時前往你的設定或佇列，關閉暫停模式。",
      "可以提前關閉瞌睡模式嗎？",
      "前往設定，點選暫停，然後選擇您想要遠離的時間。當您準備好時，隨時回來。",
      "如何在個人資料中使用瞌睡模式？",
      "不，在瞌睡模式下, 所有滑動和聊天活動都會暫停。 如果你去看你的佇列, 你會看到一個告訴你瞌睡模式將會持續多久的訊息, 並且有選項可以停用它。",
      "在瞌睡模式下，我還能滑動並聊天嗎？",
      "已經與您對話的人將會看到您的「離開」狀態（如果您選擇設定）。否則，他們將會在收件匣中以正常方式看到您的先前訊息。",
      "當我啟用瞌睡模式時，目前的配對將會看到什麼？",
      "您對您的Bumble活動有完全的控制權，並且可以隨時離開平台。",
      "你可以使用狀態更新與現有配對保持聯絡，以避免他們以為你<a href={{ghosted_article_url}}>消失</a>了。此外，這全部都是自動化的，因此你不需要發送任何消息。",
      "你可以隨時選擇停止積極約會，但不會失去現有的配對或聊天記錄",
      "依自己的規則約會或離開",
      "保留你的配對",
      "暫停您的活動，但不是您的連結",
      "為什麼我們喜愛瞌睡模式",
      "開啟Bumble應用程式，造訪您的個人檔案分頁，並點選右上角的設定齒輪。在您的設定中，點選暫停並選擇您要離開的時間。您可以隱藏您的個人檔案24小時、72小時、一週或無限期。{{__EOL__}}{{__EOL__}}請記住，在瞌睡模式下，沒有聊天的配對將過期。如果您決定設定狀態，您正在當前聊天的人將能夠看到您離開了。",
      "Bumble 的瞌睡模式如何運作",
      "當生活忙碌時，瞌睡模式將幫助您。暫停您的 Bumble 帳戶，並按照您自己的步伐約會。",
      "Bumble 瞌睡模式已開始",
      "瞌睡模式是一種簡單的方式，可以在您想要暫停交友時停用您的帳戶。您仍然可以與現有配對進行聊天，只是在您準備好重新開始滑動之前會隱藏您的個人資料，直到您準備好重新開始為止。啟用暫停模式時，您的個人資料資料和現有配對都不會遺失。",
      "暫停您的Bumble個人檔案",
      "當你在瞌睡模式時，你可以選擇通知現有的連接你正在休息。你可以從「我在旅行」、「我專注於工作」、「我在進行數字戒」或「我優先考慮自己」中選擇狀態更新。無論你的情感如何，我們都會照顧你。",
      "新增「外出」狀態",
      "啟用瞌睡模式暫停您的Bumble（或{{page_partner_name_bff}}）帳戶。您的配對將不會知道您正在瞌睡模式，除非您告訴他們。如果您決定提前從您的瞌睡模式中醒來，只需返回設定並點選「停用瞌睡模式」即可。",
      "暫停聊天",
      "在Bumble上嘗試超級滑動",
      "{{service_level_lexeme_name}} 使用者每週可以隨意取得 {{num}} [[superswipe:num]] ，但是您可以隨時增加您的額度。免費會員可以購買超級滑動的套裝方案。",
      "在 Bumble上，我可以發送多少個超級滑動？",
      "在您的個人資料上使用超級滑動後，您將立即收到通知。您還會在該人的個人資料上看到一個標籤，告訴您他們喜歡您。",
      "如何知道有人對我使用了超級滑動？",
      "對某人有所敬重嗎？有一個很好玩的方式可以打破沈默，就是使用「超級滑動」讓對方知道你對他們有興趣，讓你們更快進入聊天。",
      "Bumble 的超級滑動",
      "從人群中脫穎而出並不總是容易的，但超級滑動可以幫助您留下良好的第一印象。{{__EOL__}}{{__EOL__}}當您使用超級滑動時，將會向您喜歡的人發送通知。您的個人資料將會跳到他們的隊伍頂端，並標記上標籤。如果他們也喜歡您，就是時候開始聊天了。",
      "什麼是超級滑動？",
      "勇敢行動並脫穎而出",
      "開啟更多聊天",
      "認識更多朋友",
      "超級滑動可以幫助你...",
      "如果你有<a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a>或Bumble {{service_level_lexeme_name_one}}，每週可獲得{{num}}個免費的[[superswipe:num]]。此外，所有成員都可以從個人資料分頁購買最多 {{num_two}} 個[[superswipe:num_two]]。如果你擁有{{service_level_lexeme_name_three}}，每週將獲得 {{num_three}} 個免費的[[superswipe:num_three]]。{{__EOL__}}{{__EOL__}}使用超級滑動非常簡單。當你看到喜歡的人時，只需點選底部左角。",
      "如何使用超級滑動",
      "Apple 和 Apple 標誌是 Apple Inc. 的商標。Google Play 是 Google LLC. 的商標",
      "使用 Bumble 的旅行模式在路上約會",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}} 會員</a>可以使用旅行模式。開啟它，您的佇列將顯示您所選城市的新面孔。",
      "如何使用旅行模式？",
      "基本上，我們有一些安全限制可能會隨時間改變，但旅行模式適用於大多數目的地。",
      "我可以在任何地方使用旅行模式嗎？",
      "旅行模式將顯示您選擇的城市中心為您的位置，因此新的連接可以找到您。他們將在您的個人資料上看到一個註記，告訴他們您正在旅行中或計劃旅行。",
      "準備出發度假或前往新城市工作？開啟Bumble的旅行模式，隨時隨地進行約會。",
      "Bumble 旅行模式",
      "旅行模式是您在外旅行時建立連結的最佳工具。將您的位置設定為世界上任何地方，您的個人資料將顯示在您選擇的城市中。這意味著您可以在您的冒險旅程中隨時針對您所在的城市進行配對。",
      "旅行模式是什麼？",
      "沒有人比當地人更了解一個地方。旅行模式可以幫助您與會使您旅程更加特別的人建立連結。",
      "在七天之前至少設定您的位置於旅行城市，以便您可以預先計劃日期並與潛在配對交流。",
      "不僅是海灘旅程，您可能在家度過假期、出差或探索新城市。在任何一個地點尋找浪漫。",
      "尋找最適合的當地約會地點",
      "去探險",
      "結交有意義的連結",
      "熱愛旅行模式的原因",
      "旅行模式適用於 <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} 和 {{service_level_lexeme_name_two}}</a>會員在 Bumble。點選右上角你的個人資料中的設定齒輪，滾動直到看到旅行模式。選擇你的新位置以開始看到該區域的人。你的個人資料會通知潛在的約會對象你正在旅行模式中。",
      "旅行模式如何使用？",
      "試看看",
      "嘗試 Bumble 網頁版",
      "完全地。Bumble 網頁應用程式在 PC、Mac 和平板電腦所有主要的網頁瀏覽器上都能運作",
      "我可以在我的電腦或 Mac上使用 Bumble 嗎？",
      "是的，你可以。你可以在 Bumble 網頁應用程式中發送訊息或甚至進行視訊或音訊通話。",
      "我可以在網頁應用程式中與配對對話嗎？",
      "要登入，請選擇「登入」或點選<a href={{sign_in_url}}>這裡</a>。你可以使用 Apple ID、Facebook 或手機號碼登入。",
      "如何在網頁版上登入 Bumble？",
      "需要遠離你的手機螢幕嗎？請嘗試使用 Bumble 網頁應用程式—與你喜歡的 Bumble 體驗，在更大的螢幕上。",
      "使用 Bumble 網站",
      "在網頁上使用 Bumble 登入很簡單。只需造訪我們的網站，點選「登入」，然後選擇您通常使用的登入方法，即可使用您的Apple ID、Facebook 詳細資料或電話號碼。然後即可登入，您的聊天和配對都在原處等你。",
      "登入",
      "用你的瀏覽器登錄",
      "編輯或修改您的個人資料，並套用進階篩選器。",
      "確認是你，以提高你與另一方建立關係的機率",
      "分享可愛的新圖或刪除舊圖。",
      "更新您的個人檔案",
      "讓大家知道你是真人",
      "新增或刪除相片",
      "您可以在 Bumble 網頁版上做什麼",
      "您會在左邊找到您的聊天和配對清單，並在右邊找到潛在的新匹配。{{__EOL__}}{{__EOL__}}請使用您的左右鍵或點選營幕右邊顯示的符號檢查個人資料，並像在手機上一樣右滑喜歡即可。",
      "如何在網頁上使用 Bumble？",
      "在這裡，友善最性感，做自己最完美，且由女性採取主動！",
      "Make The First Move™ 的堡壘",
      "美好的生活離不開健康的人際關係。無論是約會、交友、拓展人際網，Bumble 都是最有力的建立人際關係的社交工具。",
      "Bumble 的重要性",
      "我們鼓勵正直、友善、平等、自信，並尊重所有階段的關係。我們更是歡迎所有性向的人，不論是要找另一半，還是單純交友。",
      "Bumble 的價值",
      "在 Bumble，女性需要邁出第一步，或是設定 Opening Move 讓所有新配對都可以回覆。我們顛覆傳統約會方式，鼓勵兩性平權。",
      "Bumble 運作方式",
      "關於我們",
      "馬上下載",
      "App 的體驗更好",
      "使約會變得更加有意思的新功能",
      "2024 年 2 月 12 日",
      "如何增加感情的情感親密度",
      "2024 年 1 月 25 日",
      "沒有使用過約會應用程式的人該如何使用 Bumble",
      "2024 年 2 月 5 日",
      "Deception Detector™",
      "約會最新消息",
      "瀏覽<b>推薦給你</b>查看我們根據你的偏好和配對推薦給你的檔案。",
      "為你精選",
      "透過<b>瞌睡模式</b>先暫停一下，準備好約會時再回歸。",
      "休息一下",
      "使用<b>視訊</b>和<b>語音通話</b>功能，在出門見面之前先認識對方。",
      "來電了",
      "查看更多",
      "使用 <b>Opening Moves</b> 讓對方開啟對話，你也不用想破腦袋。",
      "輕鬆約會",
      "透過<b>旅行模式</b>在全球配對。",
      "帶我一起飛",
      "對方檔案看起來還不錯？透過<b>超級滑動</b>讓對方知道。",
      "吸引對方注意",
      "送出 <b>Compliments</b> 讓自己脫穎而出。",
      "讓對方臉紅心跳",
      "使用<b>隱身模式</b>隱藏檔案，不讓同事、親友或前任看見你。",
      "臥底行動",
      "照自己的方式約會",
      "幾個月後，她也在那遇見她的伴娘",
      "Hannah 在 Bumble 上遇見她的老公。",
      "在一起建立小窩之前，Carène 和 You-Liang 在 Bumble 上相遇，當時 You-Liang 受到 Carène 的旅遊照片吸引。",
      "Carène 和 You-Liang",
      "Brian 和 Kaci 都派駐在海外，但他們是在 Bumble 上相遇的",
      "Brian 和 Kaci",
      "如果他們可以，你也可以",
      "邁出下一步",
      "更新內容",
      "你值得更好的體驗，我們設計的全新方式讓你無壓交友。",
      "不需要降低標準",
      "關於 Bumble",
      "我們是唯一優先考量女性約會體驗的交友應用程式，因為女性快樂的世界，是更好的世界。",
      "Make the first move™",
      "尋找想約會的對象，並勇敢邁出第一步。建立真誠的連結、透過共同興趣認識對方，並享受快樂時光。",
      "透過 Bumble Date 尋找特別的那個人",
      "尋找職涯路上的好友，與其他同業結交，尋找新工作，創造事業。",
      "透過 Bumble Bizz 拓展人脈",
      "遇見同道中人，或是不同世界的人！不論是在家中或在路上，交新朋友，一起展開新冒險！",
      "在 Bumble For Friends 上交新朋友",
      "這個平台已不再限於約會",
      "Bumble 這個約會應用程式，由女性邁出第一步，建立有意義的關係跟社群。立即下載。",
      "Bumble 的 {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "對不起，無法找到該網頁。",
      "與我們聯絡",
      "登入",
      "糟糕，出錯啦",
      "(opens in new window)",
      "熱門問題集",
      "Cookie Policy",
      "Premium",
      "Bumble - 博客",
      "工作機會",
      "聊天",
      "城市",
      "管理cookies",
      "版權所有 © 2006 – 至今。Bumble保留所有版權",
      "自信約會報告",
      "Apple和Apple標誌是Apple Inc.的商標。{{__EOL__}}Google Play標誌是Google LLC.的商標",
      "下載",
      "Facebook",
      "追蹤我們",
      "幫助中心",
      "Instagram",
      "語言",
      "LinkedIn",
      "Bumble屬於Bumble Inc",
      "隱私政策",
      "安全中心",
      "網站導覽",
      "現代奴役法案聲明",
      "條款和細則",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "天"
            ],
            "singular": [
                  "天"
            ]
      },
      "hour": {
            "plural": [
                  "小時"
            ],
            "singular": [
                  "小時"
            ]
      },
      "minute": {
            "plural": [
                  "分鐘"
            ],
            "singular": [
                  "分鐘"
            ]
      },
      "month": {
            "plural": [
                  "月"
            ],
            "singular": [
                  "月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "superswipe": {
            "plural": [
                  "“超級滑動”"
            ],
            "singular": [
                  "“超級滑動”"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
