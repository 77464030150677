import { seo } from 'components/lexemes';
import { FeatureRoutes } from 'lib/features';

import { RouteName } from 'containers/routes';

type Route = RouteName | FeatureRoutes;

type MapRouteToSeoTitleRecord = { [key in Route]: string };

// @TODO - change to lexemes
enum SeoDescription {
    index = 'Bumble has changed the way people date, create meaningful relationships & network with women making the first move. Meet new people & download Bumble.',
    about = 'Bumble is a social network that allows you to feel empowered while making connections, whether you’re dating, looking for friends, or growing your network.',
    date = 'There’s never been a better moment to meet new, empowering people. Bumble provides opportunities to safely & easily connect with others via our dating app.',
}

export const seoDescriptionFabric: (pathname: Route) => string = (pathname) => {
    const seoDescription: MapRouteToSeoTitleRecord = {
        [RouteName.index]: SeoDescription.index,
        [RouteName.about]: SeoDescription.about,
        [RouteName.date]: SeoDescription.date,
        [RouteName.localisedIndex]: SeoDescription.index,
        [RouteName.localisedAbout]: SeoDescription.about,
        [RouteName.localisedDate]: SeoDescription.date,
        [FeatureRoutes.Compliments]: seo.lxFeatureComplimentsSEOText,
        [FeatureRoutes.Incognito_mode]: seo.lxFeatureIncognitoModeSEOText,
        [FeatureRoutes.Opening_moves]: seo.lxFeatureOpeningMovesSEOText,
        [FeatureRoutes.Snooze_mode]: seo.lxFeatureSnoozeModeSEOText,
        [FeatureRoutes.Superswipe]: seo.lxFeatureSuperswipeSEOText,
        [FeatureRoutes.Travel_mode]: seo.lxFeatureTravelModeSEOText,
        [FeatureRoutes.Video_chat]: seo.lxFeatureVideoChatSEOText,
        [FeatureRoutes.Web]: seo.lxFeatureWebSEOText,
        [FeatureRoutes.LocalisedCompliments]: seo.lxFeatureComplimentsSEOText,
        [FeatureRoutes.LocalisedIncognito_mode]: seo.lxFeatureIncognitoModeSEOText,
        [FeatureRoutes.LocalisedOpening_moves]: seo.lxFeatureOpeningMovesSEOText,
        [FeatureRoutes.LocalisedSnooze_mode]: seo.lxFeatureSnoozeModeSEOText,
        [FeatureRoutes.LocalisedSuperswipe]: seo.lxFeatureSuperswipeSEOText,
        [FeatureRoutes.LocalisedTravel_mode]: seo.lxFeatureTravelModeSEOText,
        [FeatureRoutes.LocalisedVideo_chat]: seo.lxFeatureVideoChatSEOText,
        [FeatureRoutes.LocalisedWeb]: seo.lxFeatureWebSEOText,
    };
    return seoDescription[pathname as Route];
};

export const setSeoDescription = (pathname: Route) => {
    return seoDescriptionFabric(pathname as Route);
};
