/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "戻る",
      "App Storeでダウンロード",
      "Google Playで入手",
      "スライドショー",
      "スライド{{number}}",
      "次へ",
      "前へ",
      "スライド",
      "スライド{{total}}枚中{{number}}枚目",
      "個人情報の販売・共有を許可しない",
      "個人情報を販売または共有しない",
      "BumbleでComplimentsを送って、お相手のプロフィールを好意をもったことを伝え、マッチング前にお相手の注目を集めましょう。今すぐお試しください。",
      "Compliments | Complimentsの送り方 | Bumble",
      "プライベートモードを使って、あなたのBumbleのプロフィールを誰が閲覧しているかを管理して、自分のペースで出会おう。Bumbleのプライベートモードの使用方法については、こちらのガイドをご覧ください。",
      "プライベートモード | プロフィールを隠す｜Bumble",
      "BumbleのOpening Moves機能を活用して、新しい会話の始め方を試してみよう。お相手の目を引き、調子を合わせえ、心地良い会話をすばやく始めましょう。",
      "BumbleのOpening Moves | Bumble",
      "休息や自分のための時間が必要ですか？BumbleのスヌーズモードでBumbleのアカウントを一時停止機能をご活用ください。自分のタイミングで出会いましょう。",
      "スヌーズモード | アカウントの一時停止 | Bumble",
      "Bumbleで、本当につながりたいお相手を見つけましたか？ SuperSwipeで、あなたの真剣度を伝えましょう。お相手のプロフィールにある星マークをタップするか、ここで詳細をご確認ください。",
      "Bumble SuperSwipe | SuperSwipeとは？ | Bumble",
      "出張ですか？それとも個人的な旅行ですか？Bumbleのトラベルモードなら、どこを訪れた際にも、新しい場所で、新しい出会いとつながりを楽しみながら探すことができます。ぜひトラベルモードをお試しください。",
      "Bumbleのトラベルモード | どこに居ても出会える | Bumble",
      "メッセージを通じて感じるつながりが、実際に会っても相性の良さを意味しているかどうか、知りたいですか？今すぐBumbleのビデオ通話や音声通話をお試しください。バイブスの相性を確かめられます。",
      "ビデオ通話｜電話をかけよう｜Bumble",
      "Bumbleウェブで究極の機能を体験しよう。",
      "Bumbleウェブ | Bumble",
      "契約のキャンセル",
      "Bumbleホーム画面",
      "メニューを閉じる",
      "メニューを開く",
      "言語選択",
      "フッター",
      "Bumbleのアクセシビリティ",
      "AppleとAppleロゴは、Apple Inc.の商標です。",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "採用情報",
      "イベント",
      "Google Pay は Google LLC の商標です。",
      "ガイドライン",
      "株主・投資家の方へ",
      "Cookieの管理",
      "Cookieの管理",
      "現代奴隷法に関する声明",
      "回収時の注意事項",
      "プライバシーポリシー",
      "季節ごとのデートガイド",
      "利用規約",
      "アプリ",
      "ワシントン州消費者健康データ・プライバシーポリシー",
      "概要",
      "アンバサダー",
      "ブログ",
      "友達をつくってコミュニティを探そう",
      "プロフェッショナルなネットワークを構築し、キャリアアップを目指そう",
      "真剣な交際相手、カジュアルな関係、その中間など様々な出会いを探そう",
      "詳しくみる",
      "トラベルモードでグローバルに",
      "機能",
      "Compliments",
      "あなたのプロフィールが際立つように、お相手にComplimentsを贈ってみましょう。",
      "プライベートモード",
      "プライベートモードで上司、親戚、元恋人からプロフィールを隠せます。",
      "Opening Moves",
      "Opening Movesを選択して、マッチング相手からチャットを開始してもらうようにしましょう。そうすれば、あなたが開始する必要はありません。",
      "スヌーズモード",
      "スヌーズモードで出会いを一時休止できます。再び出会う準備ができた際にはすぐに再開できます。",
      "SuperSwipe",
      "バイブスに響くプロフィールがありましたか？SuperSwipeでそのことを伝えよう",
      "トラベルモード",
      "トラベルモードで、訪れた場所でお相手を見つけ、世界中どこででもマッチしましょう。",
      "ビデオ通話",
      "ビデオチャットや音声通話を利用して、対面で会う心の準備が整っているかを確認してみましょう。",
      "ウェブアプリ",
      "ブラウザでBumble体験をすぐに始めましょう。",
      "ショップ",
      "メイン",
      "スキップして本文へ",
      "Bumbleの商標",
      "お相手のことをもっと知りたいときは、Bumbleのビデオ通話を試そう",
      "個人情報を一切共有することなく、チャット内でビデオアイコンをタップするだけでビデオ通話を開始できます。",
      "Bumbleのビデオ通話はどのように機能しますか？",
      "いいえ。Bumblej上での電話とチャットはすべて、ユーザーの個人情報を安全に保護します。ご自身で伝えるまで、お相手があなたの電話番号やその他の連絡先情報を取得することはありません。",
      "マッチング相手は私の電話番号を見れますか？",
      "もちろんです！まだ対面で会う心の準備ができていない場合は、音声通話で話せます。その場合は、通話アイコンを選択します。",
      "音声通話だけ試すことはできますか？",
      "質の良い「メッセージ受信」の通知は嬉しいですよね。でも、チャットが実際に会って話すことと同様に良いものかどうかはわかりません。そこで、ビデオ通話と音声通話の出番です。",
      "Bumbleでビデオ通話",
      "<a href={{video_chat_url}}>ビデオ通話や音声通話</a>でお互いのことをよく知ることは、アプリ外で会うかどうかを決定するのに役立ちます。一番の利点はBumble上で通話する場合、電話番号を交換する必要がないので、個人情報を共有する心の準備が整う前でも、安全を維持できます。",
      "ビデオ通話で相性を確認しよう",
      "オンラインとオフラインの出会いのギャップを埋めて、対面で会いたいと思うタイミングが訪れたかどうかを判断しましょう。",
      "お相手をより深く（そしてより早く）知りましょう。直接会って話すか、電話で会話すると、お相手についてたくさんのことを知ることができます。",
      "連絡先は非公開の個人情報です。あなた自身が共有するタイミングを選び、コントロールしています。",
      "対面で会う心の準備ができているかを確認しましょう",
      "バイブスをすばやく確認する方法",
      "個人情報を明かすことなくつながりを築けます",
      "ビデオ通話や音声通話を利用すべき理由",
      "会話を始める心の準備はできましたか？Bumbleアプリのチャットから、音声通話かビデオ通話のどちらを開始するかを選択できます。ビデオ通話を開始するには、右上の灰色のビデオカメラアイコンをタップし、アイコンの隣に表示された3つのドットをクリックすると、音声通話のオプションも表示されます。",
      "ビデオ通話する方法",
      "Complimentsを試してみますか？",
      "Complimentを贈ると、受信したお相手の『For You』にあなたのプロフィールがそのComplimentとともに表示され、お相手が確認できるようになります。",
      "Bumbleで誰かをComplimentを贈るとどうなりますか？",
      "もしお相手のComplimentが目に留まったら、返答してマッチングしてみましょう。チャット画面にComplimentが表示され、通常通り<a href={{make_the_first_move_url}}>「自分から行動を起こす」</a>ルールが適用されます。",
      "Complimentに返答する方法は？",
      "{{num}}回分の[[Compliments:num]]を無料で送信できます。すべて使い切ったら、Bumbleのアプリで最大30回分をセットで購入できます。Complimentの送信時に1回分ずつ使用します（残りは気に入った別のお相手と出会うときのために取っておきましょう）。ご心配なく、有効期限はありません！",
      "Complimentsは何回まで贈ることができますか？",
      "優しい一言（や二言）は、大きな効果をもたらします。Complimentを贈りたい気持ちはあるのに、贈りかねている場合は、次の例からインスピレーションを受けてみてください。",
      "他の例を見る",
      "「（アーティスト名）の評価が高い人は、センスが良いと思います。」",
      "「あなたの笑顔は伝染する！」",
      "「あなたの経歴には笑わせてもらいました。お見事です。」",
      "Bumbleでお相手を褒める良い方法",
      "お相手の一日を輝かせてみませんか？Complimentsを試してみましょう。",
      "Bumble のCompliments",
      "BumbleのCompliments機能を使って、気になるお相手のプロフィールに好感を持っていることを伝えられます（嬉しくて思わず顔が赤くなるかもしれません）。Compliments機能は、{{service_level_lexeme_name}}の会員特典としてご利用いただけます。マッチング前にもメッセージを送受信できる方法としてもご活用ください！",
      "コンプリメント機能の仕組み",
      "Complimentを受信した場合、そのComplimentは『For You』内で送信したお相手のプロフィールに表示されます。また、あなたのプロフィールに好感を持ったお相手がいるという通知を受けます。",
      "コンプリメントを見る",
      "お相手のプロフィールページの下部にある黄色いハートのアイコンをタップすると、Complimentを送ることができます。特定の写真やプロフィールページの一部に対してコンプリメントを贈ることになるため、タップする場所が重要です！クリックすると、メッセージを入力するためのテキストボックスが開きます（最大150文字まで入力できます）。",
      "コンプリメントを送信",
      "Bumbleをダウンロード",
      "よくある質問",
      "非公開の状態で、Bumbleを閲覧しますか？",
      "プライベートモードを有効にすると、他のBumbleメンバーのプロフィールをいいね!しない限り、お相手には表示されなくなります。",
      "プライベートモードはどのように機能しますか？",
      "プライベートモードが無効の場合、年齢や居住地などの条件が一致すると、あなたのプロフィールが他のBumbleのユーザーに表示されます。プライベートモードを有効にすると、あなたがいいね!をしたお相手にのみ表示されるようになります。",
      "Bumbleでプライベートモードを使用中、誰が私を見ることができますか？",
      "プライベートモードを使用しても、<a href={{blocking_on_bumble_url}}>Bumble上で誰かをブロックする</a>ことにはなりません。この機能では、あなたがいいね!したお相手（または過去にメッセージを送ったことがあるお相手）はあなたのプロフィールを見ることができます。",
      "プライベートモードは、誰かをブロックするような機能ですか？",
      "Bumbleのプライベートモードで、あなたのプロフィールが表示されるお相手をコントロールしましょう",
      "プライベートモード",
      "新しい出会いからは少し距離を置きつつ、他のユーザーにあなたのプロフィールを見られることなくBumbleを閲覧したい場合は、プライベートモードに切り替えることができます。この機能を有効にすると、あなたが誰かをいいね!しない限り、あなたのプロフィールは非公開になります。これにより、あなた自身で共有する内容と公開するお相手をコントロールできます。",
      "プライベートモードでプロフィールを非表示",
      "あなたの写真や個人情報を見られるユーザーをあなたが決めます。",
      "プロフィールに個人的な内容を記載したい場合、現在のマッチング相手だけにこっそり共有できます。",
      "元恋人や職場の同僚など、交際対象ではない人に遭遇する心配をせずに、自由に閲覧できます。",
      "自分でコントロールしよう",
      "共有する内容を厳選",
      "気まずい出会いを避けましょう",
      "プライベートモードが愛用される理由",
      "プライベートモードは、{{service_level_lexeme_name}}または{{service_level_lexeme_name_two}}のメンバーが利用できる機能です。サービスの登録後、プロフィールタブに移動し、右上の [設定] をタップしてプライベートモードに切り替えることができます。",
      "{{service_level_lexeme_name}}にアップグレード",
      "Bumbleのプライベートモードの使い方",
      "BumbleのOpening Movesを試そう",
      "Opening Movesは気になるお相手とチャットを楽しむ、もう一つの方法です。送信するメッセージを選択したら、あとはリラックスして返答を待ち、特に目を引かれる返信があれば対応しましょう。",
      "BumbleのOpening Movesの使い方",
      "はい。ノンバイナリーや同性間のマッチングでは、どちら側もOpening Moveを設定でき、またどちら側からでも返答を送ることができます。",
      "同性間の恋愛でもBumbleでOpening Moves を活用できますか？",
      "誰かがあなたのOpening Moveに返答すると、チャットにお相手のプロフィールが表示されるようになります。マッチングが失効しないためには、女性の場合は24時間以内に返答する必要があります。その後、お相手の返答に返信するか、新しい会話を始められるようになります。",
      "Opening Movesに反応があったかどうかを確認するにはどうすればよいですか？",
      "Opening Movesは、最初の行動を起こす際のプレッシャーを軽減して、すばやく、手軽に充実した会話を始められる機能です。",
      "BumbleのOpening Moves",
      "Opening Movesは、会話のきっかけを簡単に作れる新しい方法です。「パーティーで音楽をかけるとしたら、よくかける曲は何？」など選択肢の中からOpening Moveを選ぶか、自分で新しく入力することもできます。選択しておくと、マッチング画面でマッチング相手が即座にあなたの質問に返信できます。",
      "今すぐダウンロード",
      "Opening Moveはどんな機能？",
      "プレッシャーをより少なく",
      "あなたらしさを共有しよう",
      "もっと良い会話を始めよう",
      "Opening Movesの活用法",
      "マッチングした際にマッチング相手へ送信するOpening Moveを設定できます。プロフィールページの[Opening Moves]に移動し、質問の選択肢から1つ選択するか、ご自身で質問を入力することもできます。Opening Movesはいつでも変更できます。",
      "Opening Movesを試す",
      "BumbleのOpening Movesの使い方",
      "<a href={{see_more_url}}>もっと見る</a>",
      "ちょっと休みたい？ スヌーズモードをお試しください",
      "はい、いつでも[設定]かスワイプ画面から、スヌーズモードをオフに切り替えられます。",
      "スヌーズモードを早期に解除できますか？",
      "設定に移動し、[スヌーズ] をタップして、休止中にする期間を選択します。再開する準備が整いましたらいつでも戻ってきてください。",
      "スヌーズモードをどのように使用しますか？",
      "いいえ。スヌーズモード中は、スワイプとチャットに関するすべてのアクティビティが一時的に休止状態になります。スワイプ画面に移動すると、スヌーズモードの継続期間が表示され、解除オプションも選択できます。",
      "スヌーズモード中でもスワイプやチャットを続けられますか？",
      "設定した場合、すでにメッセージを送った相手には、「退席中」ステータスが表示されます。設定していない場合は、通常通り受信トレイに前回までのメッセージが表示されます。",
      "スヌーズモードにすると、現在のマッチングにはどうなりますか？",
      "Bumbleでの活動を自分でコントロールできるので、好きな時にプラットフォームから離れられます。",
      "ステータス更新機能を利用すると、既存のマッチングを最新の状態に保つことができて、<a href={{ghosted_article_url}}>音信不通</a>になったように見られることはありません。すべて自動化されているので、メッセージを送信する必要はありません。",
      "マッチングやチャット履歴を失うことなく、積極的な出会いから一時的に休む時間も時に大切です。",
      "出会いもマッチング解除も自分のタイミングで",
      "マッチングを常に最新の状態に保とう",
      "一旦小休止中もマッチングを継続",
      "スヌーズモードを愛用する理由",
      "Bumbleアプリを開き、プロフィールタブに移動し、右上の歯車アイコンの設定をタップします。設定画面で [スヌーズ] をタップし、アプリを休止する期間を選択します。プロフィールを非表示にする期間は、24時間、72時間、1週間、または無期限から選択できます。{{__EOL__}}{{__EOL__}}最近やりとりのないチャットのマッチング相手とは、スヌーズモードの間に期限切れとなります。ステータス変更を設定すると、現在チャット中のお相手には、あなたが休止中であることが表示されます。",
      "Bumbleのスヌーズモードの仕組み",
      "忙しいときにはスヌーズモードをご活用ください。Bumbleのアカウントを一時的に停止し、自分のタイミングで出会いましょう。",
      "Bumbleのスヌーズモード",
      "スヌーズモードは、出会いから少し休憩したい時にアカウントを一時的に停止する簡単な方法です。既にマッチングしているお相手とは引き続きチャットできますが、再びスワイプを開始する準備ができるまで、それ以外のお相手のスワイプ画面にあなたのプロフィールが表示されません。スヌーズモードを有効にしても、プロフィール情報が失われたり、既存のマッチングが削除されたりすることはありません。",
      "Bumbleでプロフィールの表示を一時的に休止する方法",
      "スヌーズモードにしている間、ステータスを更新して、既存のマッチング相手に休止中であることを知らせることができます。「旅行中」、「仕事に集中しています」、「デジタルデトックス中です」、「自分時間を優先しています」から選択してください。どんなバイブスにも対応します。",
      "「退席中」ステータスを追加しよう",
      "スヌーズモードを有効にすると、Bumble（または{{page_partner_name_bff}}）のアカウントが一時休止状態になります。お相手に通知しない限り、お相手にはあなたがスヌーズモード中であることはわかりません。もし、最初に選択した期間よりも早くスヌーズモードを解除したくなった場合は[設定]から[スヌーズモードを解除]をタップするだけで解除できます。簡単です。",
      "チャットを一時的に中断しよう",
      "BumbleのSuperSwipeを試そう",
      "お好きなだけどうぞ！{{service_level_lexeme_name}}のメンバーは毎週{{num}}回分の[[superswipe:num]]を獲得でき、いつでも追加できます。無料メンバーはSuperSwipesをセット購入できます。",
      "Bumbleで何回SuperSwipeを送ることができますか？",
      "お相手があなたにSuperSwipeを送ると、すぐに通知が届きます。また、そのお相手のプロフィール画面にラベルが表示されるので、あなたに好意を寄せていることが分かります。",
      "誰かからSuperSwipeを受け取ったかどうか、どうすればわかりますか？",
      "とても気になるお相手がいますか？ 知り合うきっかけに最適な方法があります！SuperSwipeを送ってお相手にあなたの好意を伝えられるので、よりスピーディにチャットが始められます。",
      "BumbleのSuperSwipe",
      "注意を引くことは必ずしも容易ではありませんが、SuperSwipeは第一印象を良くするお手伝いをします。{{__EOL__}}{{__EOL__}}SuperSwipeを使用すると、あなたがいいね!したお相手に通知が送信されます。その後、プロフィールにラベルが表示され、お相手のリストの一番上にスキップされます。お相手があなたにいいね!を返すと、チャットが開始できます。",
      "SuperSwipeとは何ですか？",
      "大胆に、注目を集めよう",
      "もっとたくさんのチャットを始めよう",
      "もっとつながろう！",
      "SuperSwipeの活用方法",
      "<a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a>またはBumble {{service_level_lexeme_name_one}}をご利用の場合、毎週{{num}}回分の[[superswipe:num]]を無料で取得できます。さらに、すべてのメンバーはプロフィールタブから最大{{num_two}}回分の[[superswipe:num_two]]をセットで購入できます。{{service_level_lexeme_name_three}}をご利用の場合、毎週{{num_three}}回分の[[superswipe:num_three]]を取得できます。{{__EOL__}}{{__EOL__}}SuperSwipeの使い方は簡単です。気になるお相手を見つけたら、お相手のプロフィール画面の左下にある黄色い星アイコンをタップするだけです。",
      "SuperSwipeの使い方",
      "AppleおよびAppleのロゴはApple Inc.の商標です。{{__EOL__}}Google PlayはGoogle LLCの商標です。",
      "Bumbleのトラベルモードで旅先でもデートしよう",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}}Premiumメンバー</a>はトラベルモードを使用できます。トラベルモードをオンにすると、選択した都市にいるお相手がスワイプ画面に表示されます。",
      "トラベルモードをどのように使用しますか？",
      "大体的には、いくつかの安全上の制限は時とともに変更される可能性がありますが、トラベルモードはほとんどの目的地で利用できます。",
      "トラベルモードはどこででも使えますか？",
      "トラベルモードを使用すると、あなたの現在地は選択した都市の中心と表示されるため、その地域のお相手とマッチングすることができます。また、プロフィールにはトラベルモードの利用中であることが表示されるので、あなたが旅行中か、旅行の計画中であることがお相手に伝わります。",
      "Bumbleのトラベルモードをオンにして、旅先でも出会いを楽しんでください。",
      "Bumble）のトラベルモード",
      "トラベルモードは、旅行中につながりを築ける最高のツールです。 現在地を世界のどこかに設定すると、あなたのプロフィールは選択した都市で表示されます。 つまり、旅先がどこであろうと、その場所の出会いのチャンスへ参加できるということです。",
      "トラベルモードとは何ですか？",
      "現地の人々ほどその土地に詳しいお相手はいません。トラベルモードは、あなたの旅を特別なものにしてくれるお相手とつながるお手伝いをします。",
      "旅行前（7日前まで設定できます）に、旅行先の都市を設定しておくと、事前にデートやマッチングを計画できます。",
      "旅先はビーチに限りません！休暇で地元に戻ったり、仕事の出張だったり、未知の都市を散策したり。ロマンスはどこででも実現します。",
      "現地で最高のデートスポットを見つけよう",
      "冒険に出かけよう",
      "長く続く関係を築こう",
      "トラベルモードを愛用する理由",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}}と{{service_level_lexeme_name_two}}</a>のメンバーはBumbleでトラベルモードを利用できます。プロフィール画面の右上にある歯車アイコンをタップし、トラベルモードが表示されるまでスクロールします。新しい場所を選択すると、その地域のユーザーが表示されるようになります。プロフィールにはトラベルモード中であることが表示され、お相手に通知されます。",
      "トラベルモードとはどのような機能ですか？",
      "今すぐ試す",
      "ウェブ版のBumbleをお試しください。",
      "もちろんです。Bumbleのウェブアプリは、PC、Mac、タブレットのすべての主要ウェブブラウザで動作します。",
      "PCやMacでBumbleを使用できますか？",
      "はい、できます。Bumbleのウェブ版では、メッセージの送信や、ビデオ通話、音声通話も可能です。",
      "ウェブ版でマッチングしたお相手とチャットできますか？",
      "ログインするには、[ログイン] を選択するか、<a href={{sign_in_url}}>ここ</a>をクリックします。Apple ID、Facebook、または電話番号を使ってログインできます。",
      "ウェブ上のBumbleにログインするにはどうすればよいですか？",
      "携帯電話の画面から距離を置きたいですか？Bumbleのウェブ版をお試しください。おなじみのBumble体験を、そのままより大きな画面で楽しむことができます。",
      "Bumbleをウェブで使用する",
      "ウェブ版のBumbleへのログインは簡単です。Bumbleのウェブサイトへアクセスし、[ログイン] をクリックし、Apple ID、Facebookの詳細、または電話番号を使用するなど、いつものログイン方法を選択するだけで、完了です！チャットやマッチングの記録は引き継がれます。",
      "ログイン",
      "ブラウザでログイン",
      "プロフィールを編集や変更し、アドバンスフィルターを適用します。",
      "本人確認を行うとマッチングの確率を高めることができます。",
      "キュートな写真を新しく共有したり、古い写真を削除したりします。",
      "プロフィールを更新",
      "ありのままの自分をアピールしよう",
      "写真の追加または削除",
      "ウェブ版のBumbleでできること",
      "左側にチャットとマッチングリスト、右側に新しいマッチング候補が表示されます。{{__EOL__}}{{__EOL__}}左と右の矢印キーを使用するか、画面の右側にある印をクリックしてプロフィールを確認し、スマートフォンで行うようにいいね!を押します。",
      "ウェブ版のBumbleはどのように利用しますか？",
      "ここでは、優しいことがクールで、あなたらしくいることが大切で、女性が最優先です。「常に」です。",
      "自分から行動を起こす場所",
      "健全な人間関係は、ポジティブで生産的な人生を送るために重要です。 Bumbleは、恋愛相手や友達探しでも、仕事上のネットワーク開拓でも、つながりを築きながらエンパワーされるようにデザインされています。",
      "Bumbleが配慮する理由",
      "私たちは、誠実さ、優しさ、平等さ、自信、そして人間関係のすべての段階における尊重を大切にしています。恋愛相手を見つけるためでも、ただ楽しみたいだけでも、あらゆる方向性の出会いを歓迎し、祝福します。",
      "Bumbleが大切にしていること",
      "Bumbleでは、女性が自分で行動を起こすか、マッチング相手が返答できるOpening Moveを設定することを選択します。時代遅れの力関係を変え、始まりから対等であることを奨励します。",
      "Bumbleの仕組み",
      "会社概要",
      "今すぐダウンロード",
      "アプリがおすすめ。",
      "オンラインデートを、​そう、​現実的に、​楽しく​する​ため​の​機能",
      "2024年2月12日",
      "人間関係で感情的な親密さを高める方法",
      "2024年1月25日",
      "出会い系アプリを使ったことがない場合のBumbleの使い方",
      "2024年2月5日",
      "Deception Detector™",
      "記録するべき​出会いに​関する​すべての記事",
      "<b>For You</b>を閲覧すると、あなたの好みや過去のマッチング相手に基づいてキューレーションしたプロフィールが表示されます。",
      "あなた​の​ため​に​キューレーション",
      "<b>スヌーズモード</b>で出会いを一時休止できます。再び出会う準備ができた際にはすぐに再開できます。",
      "休憩をしよう",
      "<b>ビデオ通話</b>や<b>音声チャット</b>を使って、家にいながら現実に会いましょう。",
      "声に出してみよう",
      "もっと見る",
      "<b>Opening Moves</b>を選択して、マッチング相手からチャットを開始してもらうようにしましょう。そうすれば、あなたが開始する必要はありません。",
      "やる事を減らして、もっとデートをしよう",
      "<b>トラベルモード</b>で、免税でスイートなお相手をみつけ、世界のあらゆる場所でマッチングしましょう。",
      "一緒に旅をしよう",
      "バイブスに響くプロフィールをみつけたら、<b>SuperSwipe</b>でその思いを伝えましょう。",
      "お相手の注意を引こう",
      "あなたのプロフィールが際立つように、お相手に<b>Compliments</b>を贈ってみましょう。",
      "お相手を喜ばせよう",
      "<b>プライベートモード</b>で上司、親戚、元恋人からプロフィールを隠せます。",
      "プロフィールの非表示設定",
      "思い通りに​出会いを​コントロール",
      "数カ月後、彼女はBumbleで花嫁介添人の一人にも出会いました。",
      "ハンナはBumbleで今の夫と出会いました。",
      "ユーリアンがカレーヌの旅行写真に惹かれて、二人はBumbleで知り合い、小さな家を一緒に建てました。",
      "カレーヌとユーリアン",
      "ブライアンとケイシは共に海外在住中に、Bumbleで知り合いました。",
      "ブライアンとケイシ",
      "誰か​の​役​に​立てた​の​なら、​あなた​に​も​うまく​いく​かも​しれ​ない",
      "次の行動を起こそう",
      "新着情報を見る",
      "もっとデートを楽しめて、かつストレスが軽減できる素晴らしい方法を考案しました。",
      "バー​を​本来​ある​べき​位置​に​戻し​ましょう",
      "Bumbleについて",
      "Bumbleは、女性の体験を第一に考え、より良いデート体験を提供する唯一のアプリです。なぜなら、女性にとって良いことは、誰にとっても良いことだからです。",
      "Make the first move™",
      "実際にデートしたいと思うお相手をみつけて、自分から行動を起こしましょう。本物のつながりを築き、お互いの好きなことで盛り上がり、そして最も重要なのは楽しむことです。",
      "Bumble​ Date​で​出会い​を探そう",
      "仕事に有益な友達をみつけましょう。他のビジネスプロフェッショナルとつながり、次の仕事やメンター、新しいキャリアと出会いましょう。",
      "Bumble​ Bizz​で​キャリア​アップ​を​目指​そう",
      "似ている人や自分とはまったく異なる人と出会いましょう。新しい友達をつくり、一緒にできる新しいことを探すのはどうでしょう？",
      "Bumble​ For​ Friends​で​新しい​友達​を​みつけ​よう",
      "デート​の​ため​だけ​では​あり​ません",
      "Bumbleは、女性が自分から行動を起こして出会うためのマッチングアプリです。デートの仕方、有意義な関係の作り方、ネットワークの作り方を変えたアプリです。今すぐダウンロードしよう。",
      "{{social_network}}のBumble",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "申し訳ありません、お探しのページが見つかりません。",
      "お問い合わせ",
      "ログイン",
      "問題が発生しました",
      "(opens in new window)",
      "よくある質問",
      "Cookie Policy",
      "Premium",
      "Bumble ー The Blog",
      "採用情報",
      "チャット",
      "都市",
      "クッキーの管理",
      "著作権 © 2006 – 現在。Bumble（不許複製・禁無断転載）",
      "デートの信頼度レポート",
      "AppleおよびAppleのロゴはApple Inc.の商標です。{{__EOL__}}Google PlayはGoogle LLCの商標です。",
      "ダウンロード",
      "Facebook",
      "フォローはこちらから",
      "ヘルプセンター",
      "Instagram",
      "言語",
      "LinkedIn",
      "BumbleはBumble Incの一部です。",
      "プライバシーポリシー",
      "Safety Centre",
      "サイトマップ",
      "現代奴隷法",
      "利用規約",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "日"
            ],
            "singular": [
                  "日"
            ]
      },
      "hour": {
            "plural": [
                  "時間"
            ],
            "singular": [
                  "時間"
            ]
      },
      "minute": {
            "plural": [
                  "分"
            ],
            "singular": [
                  "分"
            ]
      },
      "month": {
            "plural": [
                  "ヶ月"
            ],
            "singular": [
                  "ヶ月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "superswipe": {
            "plural": [
                  "スーパースワイプ"
            ],
            "singular": [
                  "スーパースワイプ"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
