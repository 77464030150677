import { seo } from 'components/lexemes';
import { FeatureRoutes } from 'lib/features';

import { RouteName } from 'containers/routes';

type Route = RouteName | FeatureRoutes;

type MapRouteToSeoTitleRecord = { [key in Route]: string };

// @TODO - change to lexemes
export enum SeoTitle {
    index = 'Bumble | Date, Chat, Meet New People & Network Better',
    about = 'About - Bumble | Date, Chat, Meet New People & Network Better',
    date = 'Date - Bumble | Date, Chat, Meet New People & Network Better',
}

export const seoTitleFabric: (pathname: Route) => string = (pathname) => {
    const seoTitle: MapRouteToSeoTitleRecord = {
        [RouteName.index]: SeoTitle.index,
        [RouteName.about]: SeoTitle.about,
        [RouteName.date]: SeoTitle.date,
        [RouteName.localisedIndex]: SeoTitle.index,
        [RouteName.localisedAbout]: SeoTitle.about,
        [RouteName.localisedDate]: SeoTitle.date,
        [FeatureRoutes.Compliments]: seo.lxFeatureComplimentsSEOTitle,
        [FeatureRoutes.Incognito_mode]: seo.lxFeatureIncognitoModeSEOTitle,
        [FeatureRoutes.Opening_moves]: seo.lxFeatureOpeningMovesSEOTitle,
        [FeatureRoutes.Snooze_mode]: seo.lxFeatureSnoozeModeSEOTitle,
        [FeatureRoutes.Superswipe]: seo.lxFeatureSuperswipeSEOTitle,
        [FeatureRoutes.Travel_mode]: seo.lxFeatureTravelModeSEOTitle,
        [FeatureRoutes.Video_chat]: seo.lxFeatureVideoChatSEOTitle,
        [FeatureRoutes.Web]: seo.lxFeatureWebSEOTitle,
        [FeatureRoutes.LocalisedCompliments]: seo.lxFeatureComplimentsSEOTitle,
        [FeatureRoutes.LocalisedIncognito_mode]: seo.lxFeatureIncognitoModeSEOTitle,
        [FeatureRoutes.LocalisedOpening_moves]: seo.lxFeatureOpeningMovesSEOTitle,
        [FeatureRoutes.LocalisedSnooze_mode]: seo.lxFeatureSnoozeModeSEOTitle,
        [FeatureRoutes.LocalisedSuperswipe]: seo.lxFeatureSuperswipeSEOTitle,
        [FeatureRoutes.LocalisedTravel_mode]: seo.lxFeatureTravelModeSEOTitle,
        [FeatureRoutes.LocalisedVideo_chat]: seo.lxFeatureVideoChatSEOTitle,
        [FeatureRoutes.LocalisedWeb]: seo.lxFeatureWebSEOTitle,
    };

    return seoTitle[pathname as Route];
};

export const setSeoTitle = (pathname: Route) => {
    return seoTitleFabric(pathname as Route);
};
