/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Zurück",
      "Laden im App Store",
      "Jetzt bei Google Play",
      "Karussell",
      "Folie {{number}}",
      "Nächste Folie",
      "Vorherige Folie",
      "Folie",
      "Folie {{number}} von {{total}}",
      "Meine personenbezogenen Daten nicht verkaufen oder weitergeben",
      "Meine personenbezogenen Daten nicht verkaufen oder weitergeben",
      "Sende Compliments auf Bumble, um zu zeigen, dass dir ein Profil gefällt und mach noch vor dem Match auf dich aufmerksam. Probiere es jetzt aus.",
      "Compliments | Wie du Komplimente sendest | Bumble",
      "Date in deinem eigenen Tempo und behalte die Kontrolle darüber, wer dein Bumble-Profil sieht, mit dem Inkognito-Modus. Erfahre in unserem Guide, wie du den Inkognito-Modus auf Bumble nutzt.",
      "Inkognito-Modus | Verstecke dein Profil | Bumble",
      "Starte das Gespräch mal anders mit der Funktion Opening Moves von Bumble. Errege Aufmerksamkeit, gib die Stimmung vor und komme schneller zu tiefen Gesprächen.",
      "Bumble Opening Moves | Bumble",
      "Brauchst du eine kleine Pause oder etwas Zeit für dich? Dann nutze den Snooze-Modus von Bumble, um dein Bumble-Konto zu pausieren. So datest du ganz nach deinen Regeln.",
      "Snooze-Modus | Pausiere dein Konto | Bumble",
      "Jemanden gesehen, der dir richtig gut gefällt auf Bumble? Zeig mit einem SuperSwipe, dass du es ernst meinst. Tippe auf den Stern im Profil, um dein Like zu betonen, oder erfahre hier mehr Details.",
      "Bumble SuperSwipe | Was ist ein SuperSwipe? | Bumble",
      "Geht's in den Urlaub oder auf Geschäftsreise? Der Reise-Modus auf Bumble hilft dir dabei, an jedem Ort neue, interessante Leute kennenzulernen. Probiere jetzt den Reisemodus aus.",
      "Bumbles Reise-Modus | Date, wo immer du hingehst | Bumble",
      "Willst du wissen, ob eure Connection nicht nur in Nachrichten flowt, sondern auch im echten Leben? Dann teste den Vibe im Videochat oder per Sprachanruf auf Bumble aus.",
      "Videochat | Einfach anrufen | Bumble",
      "Erlebe die Faszination von Bumble Web, das ultimative Feature.",
      "Bumble Web | Bumble",
      "Verträge hier kündigen",
      "Bumble Startseite",
      "Menü schließen",
      "Menü öffnen",
      "Sprachauswahl",
      "Fußzeile",
      "Barrierefreiheit bei Bumble",
      "Apple und das Apple logo sind Marken von Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Karriere",
      "Events",
      "Google Play ist eine Marke von Google LLC.",
      "Richtlinien",
      "Investoren",
      "Cookies verwalten",
      "Cookies verwalten",
      "Erklärung zum Modern Slavery Act",
      "Hinweis zur Datenerhebung",
      "Datenschutz",
      "Saisonale Dating-Ratgeber",
      "AGB",
      "Die App",
      "Washingtoner Datenschutzrichtlinie für Gesundheitsdaten von Verbrauchern",
      "Über uns",
      "Ambassadors",
      "Blog",
      "Neue Freunde und deinen Tribe finden",
      "Beruflich netzwerken und die Karriere vorantreiben",
      "Eine Beziehung finden, etwas Lockeres oder irgendwas dazwischen",
      "Mehr erfahren",
      "Im Reise-Modus die Welt erkunden",
      "Funktionen",
      "Compliments™",
      "Verteile Komplimente, um herauszustechen und zu connecten.",
      "Inkognito-Modus",
      "Nutze Inkognito-Modus, wenn nur Leute dein Profil sehen sollen, die für dich infrage kommen, nicht dein Chef oder deine Verwandten.",
      "Opening Moves",
      "Leg deinen Opening Move fest und gib deinem Match ein Gesprächsthema vor, um den Chat zu starten.",
      "Snooze-Modus",
      "Pause vom Dating? Nutze den Snooze-Modus. Komm einfach zurück, wenn du wieder Lust hast und mach da weiter, wo du warst.",
      "SuperSwipe",
      "Ein Profil, das dir richtig gut gefällt? Zeig, dass es was besonderes ist, mit einem SuperSwipe.",
      "Reise-Modus",
      "Finde deinen Duty-Free-Snack und matche überall auf der Welt, mit dem Reise-Modus.",
      "Video-Chat",
      "Nutze Videochat und Audioanrufe, um den Vibe zu checken, bevor du auf ein Date gehst.",
      "Web-App",
      "Erlebe Bumble direkt in deinem Browser.",
      "Der Shop",
      "Hauptmenü",
      "Zum Hauptteil springen",
      "Bumble Logo",
      "Willst du mehr wissen? Nutze den Video-Chat auf Bumble.",
      "Tippe einfach auf das Video-Symbol in deinem Chat, um einen Videoanruf zu starten, ohne persönliche Kontaktinformationen preiszugeben.",
      "Wie funktioniert der Videochat auf Bumble?",
      "Nein. Bei allen Anrufen und Chats auf Bumble sind deine persönlichen Daten sicher und geschützt. Deine Nummer oder andere Kontaktdaten werden erst dann weitergegeben, wenn du es zulässt.",
      "Kann mein Match meine Telefonnummer sehen?",
      "Auf jeden Fall! Wenn du noch nicht bereit für ein Face-to-face Treffen bist, kannst du dich per Sprachanruf über Audio verbinden. Wähle stattdessen einfach das Anrufsymbol aus.",
      "Kann ich einfach einen Sprachanruf ausprobieren?",
      "Es gibt doch nichts schöneres als die Benachrichtigung *Neue Nachricht erhalten*. Und klar kann man nur schwer sagen, ob ein Gespräch auch im echten Leben gut flowen wird. Deshalb kommen hier Video- und Sprachanrufe ins Spiel.",
      "Videochat auf Bumble",
      "<a href={{video_chat_url}}>Videochats und Sprachanrufe</a> können euch dabei helfen, einander kennenzulernen und zu entscheiden, ob ihr die Dinge offline nehmen möchtet. Und das Beste daran? Wenn du über Bumble telefonierst, musst du keine Telefonnummern austauschen – so bleiben deine persönlichen Daten sicher, bis du bereit bist, sie zu teilen.",
      "Teste die Chemie im Videochat",
      "Schließe die Lücke zwischen Online- und Offline-Dating und entscheide selbst, wann es an der Zeit ist, den Sprung zum Date in real life zu wagen.",
      "Lerne dein Match besser (und schneller) kennen. Ein persönliches Gespräch oder ein Telefongespräch kann viel über jemanden verraten.",
      "Du hast die Kontrolle. Wir werden deine Kontaktdaten vertraulich behandeln, sodass du selbst entscheiden kannst, wann du sie weitergeben möchtest.",
      "Finde heraus, ob du dein Match IRL treffen möchtest",
      "Mach einen kurzen Vibe-Check",
      "Ohne persönliche Angaben verbinden",
      "Warum du Videochats und -anrufe nutzen solltest",
      "Bist du bereit für ein Gespräch? Du kannst direkt in deinem Chat in der Bumble-App wählen, ob du einen Audio- oder Videoanruf starten möchtest. Um einen Videoanruf zu starten, tippe auf das graue Videokamera-Symbol in der oberen rechten Ecke. Neben dem Symbol werden auch drei Punkte angezeigt. Klicke darauf, um die Option für einen Audioanruf anzuzeigen.",
      "Wie funktioniert der Videochat?",
      "Bereit, Compliments auszuprobieren",
      "Wenn du jemandem ein Kompliment sendest, sieht die Person es, sobald du in ihrem Bereich „Für dich“ auftauchst.",
      "Was passiert, wenn du jemandem auf Bumble ein Kompliment sendest?",
      "Wenn dir das Kompliment der Person gefällt, dann matche mit ihr. Du siehst das Kompliment in deinem Chat und es gelten die üblichen Regeln von <a href={{make_the_first_move_url}}>Mach den ersten Schritt</a>.",
      "Wie antworte ich auf ein Kompliment?",
      "Du kannst {{num}} [[Compliments:num]] kostenlos versenden. Wenn du alle aufgebraucht hast, kannst du in der App Bumble weitere Komplimente in Paketen von bis zu 30 Stück kaufen. Eines davon wird zum Versenden des Kompliments verwendet (hebe den Rest für den Fall auf, dass dir ein anderes Profil gefällt). Keine Sorge, sie verfallen nicht!",
      "Wie viele Komplimente kann ich senden?",
      "Ein nettes Wort (oder zwei) kann viel bewirken. Wenn du ein Kompliment machen möchtest, aber keine Ahnung hast, wie du anfangen sollst, dann lass dich von diesen Ideen inspirieren:",
      "Mehr Beispiele ansehen",
      "„Wer [Künstlername] mag, hat einen guten Geschmack.”",
      "„Dein Lachen wirkt ansteckend“",
      "„Deine Bio hat mich echt zum Lachen gebracht. Super!“",
      "Wie sende ich bessere Komplimente auf Bumble?",
      "Willst du jemanden happy machen? Klingt, als sei es Zeit für Compliments™.",
      "Compliments™ auf Bumble",
      "Nutze Compliments auf Bumble, um einem potenziellen Match mitzuteilen, dass dir das Profil gefällt (und mach die Person happy!). Komplimente sind Teil deiner {{service_level_lexeme_name}}-Mitgliedschaft. Sie sind auch eine Möglichkeit, Nachrichten zu senden und zu empfangen, bevor du überhaupt ein Match hast.",
      "Wie Compliments funktioniert",
      "Wenn du ein Kompliment bekommst, wird es im Profil des Absenders in „Für dich“ angezeigt. Du erhältst außerdem eine Benachrichtigung, dass jemandem ein bestimmter Teil deines Profils gefällt.",
      "Deine Komplimente ansehen",
      "Du kannst ein Compliment senden, indem du auf das gelbe Herzsymbol unten im Profil einer Person tippst. Es ist wichtig, wo du tippst, denn du machst diesem bestimmten Foto oder Teil des Profils ein Kompliment! Wenn du klickst, öffnet sich ein Textfeld, in das du deine Nachricht schreiben kannst (maximal 150 Zeichen).",
      "Sende ein Kompliment",
      "Bumble herunterladen",
      "Häufig gestellte Fragen",
      "Bumble inkognito durchstöbern?",
      "Wenn du den Inkognito-Modus aktivierst, wirst du anderen Mitgliedern von Bumble nicht angezeigt, es sei denn, du likest deren Profil.",
      "Wie funktioniert der Inkognito-Modus?",
      "Ohne den Inkognito-Modus ist dein Profil für andere Mitglieder von Bumble sichtbar, wenn du ihren Präferenzen, wie Alter und Wohnort, entsprichst. Wenn du den Inkognito-Modus aktivierst, können andere Personen dich erst dann sehen, wenn du sie geliked hast.",
      "Wer kann mich im Incognito-Modus von Bumble sehen?",
      "Der Inkognito-Modus ist nicht dasselbe wie <a href={{blocking_on_bumble_url}}>jemanden auf Bumble zu blockieren</a>. Mit dieser Funktion können nur Leute dein Profil sehen, denen du schon ein Like gegeben hast (oder denen du schon eine Nachricht gesendet hast).",
      "Ist der Inkognito-Modus dasselbe wie jemanden blockieren?",
      "Kontrolliere selbst, wer dein Profil sieht, mit dem Bumble Inkognito-Modus",
      "Inkognito-Modus auf Bumble",
      "Wenn du eine Auszeit vom Dating oder Bumble browsen möchtest, ohne dass andere Nutzer dein Profil sehen, kannst du den Inkognito-Modus aktivieren. Wenn du das tust, bist du für alle unsichtbar – es sei denn, du likest jemanden. So kannst du steuern, was du teilst und wer dich sieht.",
      "Profil verbergen im Inkognito-Modus",
      "Du entscheidest, wer deine Fotos und persönlichen Infos sehen kann.",
      "Möchtest du etwas Persönliches in dein Profil schreiben? Du kannst diskret mit deinen aktuellen Matches teilen, was du möchtest.",
      "Stöbere frei herum, ohne dir Sorgen zu machen, dass du einem Ex, einem Arbeitskollegen oder jemandem, der nicht auf deinem Dating-Radar steht, über den Weg läufst.",
      "Du hast die volle Kontrolle",
      "Welche Infos willst du teilen?",
      "Peinliche Momente vermeiden",
      "Warum du den Inkognito-Modus lieben wirst",
      "Der Inkognito-Modus ist nur für {{service_level_lexeme_name}}- und {{service_level_lexeme_name_two}}-Mitglieder. Sobald du dich angemeldet hast, kannst du den Inkognito-Modus aktivieren, indem du den Bereich „Profil“ öffnest, oben rechts auf „Einstellungen“ tippst und den Schieberegler aktivierst.",
      "Gönn dir {{service_level_lexeme_name}}",
      "Wie du den Inkognito-Modus auf Bumble nutzt",
      "Nutze Opening Moves auf Bumble",
      "Opening Moves ist eine weitere Möglichkeit, mit Leuten zu chatten, die du magst. Sobald du eine Nachricht ausgewählt hast, die gesendet werden soll, kannst du dich entspannen, auf die Antworten warten und auf diejenigen antworten, die wirklich hervorstechen.",
      "Wie du Bumble Opening Moves nutzt",
      "Bei nicht-binären und gleichgeschlechtlichen Matches kann jede Person einen Opening Move festlegen und beantworten.",
      "Kann ich Bumble Opening Moves nutzen, um Leute desselben Geschlechts zu daten?",
      "Wenn jemand auf deinen Opening Move antwortet, siehst du das Profil in deinen Chats. Frauen haben 24 Stunden Zeit, um zu antworten, bevor das Match abläuft. Du kannst dann entweder auf die erhaltene Antwort antworten oder ein neues Gespräch beginnen.",
      "Wie weiß ich, ob jemand auf meinen Opening Move geantwortet hat?",
      "Opening Moves ist unsere Art, den Druck beim ersten Schritt rauszunehmen, und eine einfachere und schnellere Möglichkeit zu bieten, ein gutes Gespräch zu starten.",
      "Opening Moves auf Bumble",
      "Gut, dass du fragst. Opening Moves ist eine neue, einfachere Möglichkeit, ein Gespräch zu beginnen. Du kannst einen vorgeschlagenen Opening Move wie „Was ist dein Lieblingssong, wenn du auf einer Party an den Aux-Regler gelassen wirst?“ wählen oder deinen eigenen schreiben. Wenn du ein Gesprächsthema festgelegt hast, können deine Matches auf direkt drauf antworten, sobald das Match zustande kommt.",
      "Jetzt herunterladen",
      "Was ist ein Opening Move?",
      "Den Druck rauszunehmen",
      "Deinen Vibe zu zeigen",
      "Bessere Gespräche zu starten",
      "Opening Moves können helfen...",
      "Du kannst einen Opening Move festlegen, der gesendet wird, wenn du mit anderen Datern matchsr. Öffne einfach die Opening Moves in deinem Profil, wähle eine der vorformulierten Fragen aus oder werde kreativ und schreibe deine eigene. Du kannst deine Opening Moves jederzeit ändern.",
      "Opening Moves ansehen",
      "Wie du Bumble Opening Moves nutzt",
      "<a href={{see_more_url}}>Mehr anzeigen</a>",
      "Brauchst du eine Pause? Probier mal den Snooze-Modus.",
      "Ja, du kannst jederzeit deine Einstellungen oder deinen Feed aufrufen und Snooze ausschalten.",
      "Kann ich den Snooze-Modus früher beenden?",
      "Öffne die Einstellungen, tippe auf Snooze und wähle aus, wie lange du weg sein möchtest. Komme zurück, wann immer du dich bereit fühlst.",
      "Wie verwende ich den Snooze-Modus im Profil?",
      "Nein – während du dich im Snooze-Modus befindest, werden alle Swipe-and-Chat-Aktivitäten unterbrochen. Wenn du den Feed für neue Leute öffnest, siehst du eine Nachricht, die sagt, wie lange der Snooze-Modus noch läuft, und dir anbietet, ihn zu beenden.",
      "Kann ich noch swipen und chatten im Snooze-Modus?",
      "Leue, denen du schon eine Nachricht gesendet hast, sehen deinen Abwesenheitsstatus (sofern du einen festgelegt hast). Andernfalls sehen sie deine vorherigen Nachrichten wie gewohnt in ihrem Posteingang.",
      "Was sehen aktuelle Matches, wenn ich im Snooze-Modus bin?",
      "Du hast die volle Kontrolle über deine Aktivität bei Bumble und kannst dir jederzeit eine Auszeit von der Plattform nehmen.",
      "Du kannst bestehende Connections mit einem Status-Update auf dem Laufenden halten, damit es nicht so aussieht, als hättest du sie <a href={{ghosted_article_url}}>geghostet</a>. Außerdem ist alles automatisiert – du musst also keine Nachrichten senden.",
      "Mach ruhig mal Pause von aktivem Dating, ohne deine Connections oder Chats zu verlieren.",
      "Date nach deinen Regeln",
      "Sag deinen Connections Bescheid",
      "Mach 'ne Pause, aber nicht von deinen Matches",
      "Warum wir den Snooze-Modus lieben",
      "Öffne die Bumble-App, öffne den Profil-Reiter und tippe auf das Einstellungssymbol in der oberen rechten Ecke. In deinen Einstellungen tippst du auf Snooze und wählst aus, wie lange du weg sein wirst. Du kannst dein Profil für 24 Stunden, 72 Stunden, eine Woche oder unbegrenzt ausblenden.{{__EOL__}}{{__EOL__}}Denk daran, dass Matches ohne aktive Chats ablaufen, während du im Snooze-Modus bist. Wenn du einen Abwesenheitsstatus einstellst, können die Personen, mit denen du gerade chattest, sehen, dass du weg bist.",
      "Wie der Snooze-Modus auf Bumble funktioniert",
      "Wenn das Leben hektisch wird, kann der Snooze-Modus helfen. Pausiere dein Bumble-Konto und date nach deinen Regeln.",
      "Snooze-Modus auf Bumble",
      "Der Snooze-Modus ist eine einfache Möglichkeit, dein Konto zu pausieren, wenn du eine Auszeit vom Dating nehmen willst. Du kannst weiterhin mit deinen bestehenden Matches chatten, dein Profil wird nur für andere Nutzer ausgeblendet, bis du wieder bereit bist, zu swipen. Durch die Aktivierung des Snooze-Modus verlierst du weder deine Profilinformationen noch löschst du bestehende Kontakte.",
      "Snooze dein Bumble-Profil",
      "Während du im Snooze-Modus bist, kannst du bestehende Matches über ein Status-Update wissen lassen, dass du eine Auszeit nimmst. Wähle die passende Botschaft aus: „Ich bin verreist“, „Arbeit geht zurzeit vor“, „Ich mache Digital Detox“ oder „Ich brauche Zeit für mich“. Was auch immer grade los ist, wir haben das Richtige für dich.",
      "Gib Abwesenheitsstatus an",
      "Durch Aktivieren des Snooze-Modus wird dein Konto bei Bumble (oder {{page_partner_name_bff}}) pausiert. Deine Matches erfahren von deinem Snooze-Modus erst, wenn du es ihnen mitteilst. Wenn du früher als ursprünglich geplant den Snooze-Modus beenden möchtest, gehe einfach zurück in die Einstellungen und tippe auf „Snooze-Modus deaktivieren“. So einfach ist das.",
      "Pausiere deine Chats",
      "Nutze SuperSwipe auf Bumble",
      "So viele du willst! {{service_level_lexeme_name}} Benutzer erhalten jede Woche {{num}} [[superswipe:num]], aber du kannst deinen Vorrat jederzeit auffüllen. Mitglieder mit dem kostenlosen Basistarif können SuperSwipe-Pakete kaufen.",
      "Wie viele SuperSwipes kann ich auf Bumble senden?",
      "Wenn jemand deinem Profil ein SuperSwipe gibt, wirst du sofort benachrichtigt. Außerdem siehst du im Profil der Person eine besondere Markierung, dass sie dich mag.",
      "Wie erfahre ich, ob ich ein SuperSwipe bekommen habe?",
      "Ernsthaft Interesse? Es gibt eine coole Art, das Eis zu brechen. Sende einfach einen SuperSwipe, um zu zeigen, dass du an jemandem interessiert bist, und komm schneller ins Gespräch.",
      "SuperSwipe auf Bumble",
      "Sich von der Masse abzuheben, ist nicht immer einfach, aber SuperSwipe kann dir dabei helfen, einen guten ersten Move zu machen.{{__EOL__}}{{__EOL__}}Wenn du einen SuperSwipe verwendest, wird eine Benachrichtigung an die Person gesendet, der du ein „Like“ gegeben hast. Du wirst dann ganz vorne im Feed dieser Person gezeigt mit einer besonderen Markierung. Wenn der andere dir auch ein „Like“ gibt, könnt ihr mit dem Chatten beginnen.",
      "Was ist ein SuperSwipe?",
      "Sei mutig und fall auf",
      "Starte mehr Chats",
      "Finde mehr Connections",
      "SuperSwipe kann dir helfen…",
      "Wenn du <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> oder Bumble {{service_level_lexeme_name_one}} hast, erhältst du {{num}} [[superswipe:num]] kostenlos pro Woche. Außerdem können alle Mitglieder im Reiter „Profil“ Pakete mit bis zu {{num_two}} [[superswipe:num_two]] kaufen. Wenn du {{service_level_lexeme_name_three}} hast, erhältst du {{num_three}} [[superswipe:num_three]] pro Woche.{{__EOL__}}{{__EOL__}}SuperSwipen ist ganz einfach: Wenn dir jemand gefällt, tippe unten in deren Profil einfach auf das gelbe Sternsymbol.",
      "So funktionieren SuperSwipes",
      "Apple und das Apple logo sind Marken von Apple Inc. Google Play ist eine Marke von Google LLC.",
      "Date wo immer du gerade bist, mit Bumbles Reise-Modus",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}}-Mitglieder</a> können den Reise-Modus nutzen. Wenn du ihn einschaltest, siehst du in deinem Feed neue Gesichter aus einer Stadt deiner Wahl.",
      "Wie nutze ich den Reise-Modus?",
      "So ziemlich – wir haben ein paar Sicherheitsbeschränkungen, die sich im Laufe der Zeit ändern können, aber der Reise-Modus ist für die meisten Ziele verfügbar.",
      "Kann ich den Reise-Modus überall nutzen?",
      "Im Reise-Modus wird dein Standort als Zentrum deiner ausgewählten Stadt angezeigt, sodass neue Kontakte in der Umgebung dich finden können. Sie sehen in deinem Profil einen Hinweis, dass du dich im Reise-Modus befindest, sodass sie wissen, dass du zu Besuch bist oder einen Besuch planst.",
      "Packst du deine Koffer für den Urlaub oder ziehst du in eine neue Stadt? Aktiviere den Reise-Modus von Bumble und date auch, wenn du unterwegs bist.",
      "Reise-Modus auf Bumble",
      "Der Reise-Modus ist das ultimative Tool, um unterwegs Leute kennenzulernen. Wähle einen beliebigen Ort auf der Welt aus, wo dein Profil angezeigt werden soll. So kannst du überall dort daten, wo dich deine Abenteuer hinführen.",
      "Was ist der Reise-Modus?",
      "Niemand kennt einen Ort besser als die Locals. Der Reise-Mode kann dir dabei helfen, mit den Leuten in Kontakt zu treten, die deine Reise zu etwas ganz Besonderem machen können.",
      "Ändere deinen Standort bis zu sieben Tage vor deiner Abreise in die Stadt deines Aufenthalts, damit du Dates und Treffen im Voraus planen kannst.",
      "Denke über Strandurlaub hinaus – vielleicht bist du über die Feiertage zu Hause, auf Geschäftsreise oder erkundest eine neue Stadt. Finde Romantik, wo auch immer du landest.",
      "Finde die besten Orte für ein Date in deiner Nähe",
      "Ein Abenteuer erleben",
      "Finde echte Kontakte",
      "Gründe, den Reise-Modus zu lieben",
      "Den Reise-Modus können Mitglieder von <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} und {{service_level_lexeme_name_two}}</a> auf Bumble nutzen. Tippe oben rechts in deinem Profil auf das Zahnrad für Einstellungen und scrolle runter, bis du den Reise-Modus siehst. Wähle dann einen neuen Standort aus, um die Leute vor Ort zu sehen. In deinem Profil wird für potenzielle Matches angezeigt, dass du dich im Reise-Modus befindest.",
      "Wie funktioniert der Reise-Modus?",
      "Ausprobieren",
      "Besuche die Bumble Website",
      "Absolut. Die Bumble-Web-App funktioniert auf PCs, Macs und Tablets in allen gängigen Webbrowsern.",
      "Kann ich Bumble auf meinem PC oder Mac verwenden?",
      "Ja, das kannst du. Du kannst Nachrichten senden oder sogar einen Video- oder Audioanruf über die Web-App von Bumble tätigen.",
      "Kann ich mit meinen Matches in der Web-App chatten?",
      "Um dich anzumelden, wähle „Anmelden“ oder klicke <a href={{sign_in_url}}>hier</a>. Du kannst dich mit deiner Apple-ID, Facebook oder Telefonnummer anmelden.",
      "Wie melde ich mich auf der Bumble Website an?",
      "Brauchst du eine Pause vom Handyscreen? Probiere die Web-App von Bumble aus – das gleiche Bumble-Erlebnis, das du liebst, auf dem großen (bzw. größeren) Bildschirm.",
      "Date auf der Bumble Website",
      "Die Anmeldung auf der Bumble Website ist ganz einfach. Ruf einfach die Website auf, klicke auf „Anmelden“ und wähle deine übliche Anmeldemethode mit deiner Apple-ID, deinem Facebook-Login oder deiner Telefonnummer. Voilà! Du bist drin und deine Chats und Matches sind genau da, wo du sie zurückgelassen hattest.",
      "Einloggen",
      "Melde dich über den Browser an",
      "Bearbeite dein Profil und wende erweiterte Filter an.",
      "Bestätige, dass du echt bist, um die Chancen zu erhöhen, dass du ein tolles Match findest.",
      "Lade neue Bilder hoch oder lösche die älteren.",
      "Update dein Profil",
      "Zeig, dass du wirklich du bist",
      "Lade Fotos hoch oder entferne sie",
      "Was du auf der Bumble Website tun kannst!",
      "Links findest du deine Chats und eine Liste deiner Connections und rechts potenzielle neue Matches.{{__EOL__}}{{__EOL__}}Nutze die Pfeiltasten nach links und rechts oder klicke auf die Symbole auf der rechten Seite des Bildschirms, um dir Profile anzusehen und Personen zu liken, wie du es auf deinem Handy tun würdest.",
      "Wie nutze ich die Bumble Website?",
      "Hier gilt: Kindness ist sexy, du bist perfekt, wie du bist, und Frauen kommen zuerst. Immer.",
      "Das Zuhause von Mach den ersten Schritt™",
      "Gesunde Beziehungen sind wichtig für ein glückliches Leben. Bumble ist dafür gemacht, dass du genau solche Beziehungen findest - egal, ob du Herzklopfen suchst, neue Freunde finden oder beruflich networken willst.",
      "Warum Bumble wichtig ist",
      "Wir setzen uns für Integrität, Kindness, Gleichberechtigung, Vertrauen und Respekt in jeder Phase einer Beziehung ein. Wir unterstützen nicht nur jede Orientierung, wir feiern sie - egal, ob du hier die große Liebe finden oder einfach nur Spaß haben willst.",
      "Was Bumble wertschätzt",
      "Auf Bumble bestimmen Frauen, was sie wollen, indem sie selbst den ersten Schritt machen oder einen Opening Move vorgeben, auf den alle Matches antworten können. Das verändert die altmodische Machtdynamik und fördert die Gleichberechtigung von Anfang an.",
      "Wie Bumble funktioniert",
      "Über uns",
      "Jetzt herunterladen",
      "Die App ist sogar noch besser.",
      "Features, die Online-Dating einfach viel angenehmer machen",
      "12. Februar 2024",
      "Wie du emotionale Intimität in deiner Beziehung förderst",
      "25. Jan. 2024",
      "Wie du Bumble nutzt, wenn du noch nie eine Dating-App benutzt hast",
      "5. Februar 2024",
      "Deception Detector™",
      "Druckreife Dating-News",
      "Browse in <b>Für dich</b>, um empfohlene Profile zu sehen, die zu deinen Vorlieben und früheren Matches passen.",
      "Für dich ausgewählt",
      "Pause vom Dating? Nutze den <b>Snooze-Modus</b>. Komm einfach zurück, wenn du wieder Lust hast und mach da weiter, wo du warst.",
      "Mach eine Pause",
      "Lern dein Match besser kennen, bevor du IRL auf ein Date gehst. Nutze <b>Videoanrufe</b> und <b>Sprachnachrichten</b>.",
      "Hör dir den Vibe an",
      "Mehr anzeigen",
      "Leg deinen <b>Opening Move</b> fest und gib deinem Match ein Gesprächsthema vor, um den Chat zu starten.",
      "Mehr Chats, ohne Stress",
      "Finde deinen Duty-Free-Snack und matche überall auf der Welt, mit dem <b>Reise-Modus</b>.",
      "Nimm uns mit",
      "Ein Profil, das dir richtig gut gefällt? Zeig, dass es was besonderes ist, mit einem <b>SuperSwipe</b>.",
      "Einfach auffallen",
      "Wenn dir was gefällt in einem Profil, schick ein <b>Kompliment</b> und connecte, um herauszustechen.",
      "Mach jemanden happy",
      "Nutze <b>Inkognito-Modus</b>, wenn nur Leute dein Profil sehen sollen, die für dich infrage kommen, nicht dein Chef oder deine Verwandten.",
      "Geh undercover",
      "Date nach deinen Regeln",
      "Ein paar Monate später traf sie dort auch eine ihrer Brautjungfern",
      "Hannah lernte ihren jetzigen Ehemann auf Bumble kennen.",
      "Bevor sie zusammen ihr Tiny-House bauten, lernten sich Carène und You-Liang auf Bumble kennen, wo You-Liang von Carènes Reisefotos fasziniert war.",
      "Carène und You-Liang",
      "Brian und Kaci waren zusammen im Ausland stationiert - aber sie haben sich auf Bumble kennengelernt",
      "Brian und Kaci",
      "Wenn es für sie funktioniert hat, könnte es auch für dich funktionieren.",
      "Mach den nächsten Move",
      "Neues entdecken",
      "Du hast was Besseres verdient, deshalb haben wir für dich tolle neue Möglichkeiten geschaffen, mit denen du mehr Dates findest und zwar ohne den ganzen Stress.",
      "Leg die Messlatte wieder höher, wo sie hingehört",
      "Über Bumble",
      "Bumble ist die einzige App, die Dating besser macht, denn hier steht das Erlebnis von Frauen im Vordergrund. Wenn etwas besser ist für Frauen, dann ist es besser für alle.",
      "Make the first move™",
      "Finde jemanden, den du so richtig gerne daten willst, und dann mach den ersten Schritt. Geh in die Connection, bonde über Dinge, die ihr beide liebt, und vor allem: habt Spaß dabei.",
      "Lern jemanden kennen mit Bumble Date",
      "Finde Freunde mit Work-Benefits. Connecte mit anderen Professionals, um deinen nächsten Job, einen Mentor oder sogar eine neue Karriere zu finden.",
      "Mach Boss-Moves mit Bumble Bizz",
      "Lerne Leute kennen, die so sind wie du. Oder anders. Finde neue Freunde und entdecke Dinge, die ihr zusammen unternehmen könnt - ob zu Hause oder unterwegs.",
      "Finde neue Freunde auf Bumble For Friends",
      "So viel mehr als Dating",
      "Bumble ist die Dating-App, bei der Frauen den ersten Schritt machen. Die App, die revolutioniert hat, wie wir heute daten, gesunde Beziehungen aufbauen und networken. Jetzt herunterladen.",
      "Bumble auf {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Wir konnten die gesuchte Seite leider nicht finden.",
      "Kontakt",
      "Einloggen",
      "Sorry, da ist was schiefgegangen!",
      "(opens in new window)",
      "FAQ",
      "Cookie Policy",
      "Premium",
      "Bumble – Blog",
      "Karriere",
      "Chat",
      "Städte",
      "Cookies verwalten",
      "Copyright © 2006 bis heute. Bumble. Alle Rechte vorbehalten.",
      "Bericht: Zuversicht beim Dating",
      "Apple und das Apple logo sind Marken von Apple Inc.{{__EOL__}}Google Play ist eine Marke von Google LLC.",
      "Jetzt downloaden",
      "Facebook",
      "Wir sind auch hier:",
      "Hilfebereich",
      "Instagram",
      "Sprache",
      "LinkedIn",
      "Bumble ist Teil von Bumble Inc.",
      "Datenschutzrichtlinien",
      "Safety Centre",
      "Sitemap",
      "Erklärung zum Modern Slavery Act",
      "AGB",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "Tage",
                  "Tage",
                  "Tagen",
                  "Tage"
            ],
            "singular": [
                  "Tag",
                  "Tags",
                  "Tag",
                  "Tag"
            ]
      },
      "hour": {
            "plural": [
                  "Stunden",
                  "Stunden",
                  "Stunden",
                  "Stunden"
            ],
            "singular": [
                  "Stunde",
                  "Stunde",
                  "Stunde",
                  "Stunde"
            ]
      },
      "minute": {
            "plural": [
                  "Minuten",
                  "Minuten",
                  "Minuten",
                  "Minuten"
            ],
            "singular": [
                  "Minute",
                  "Minute",
                  "Minute",
                  "Minute"
            ]
      },
      "month": {
            "plural": [
                  "Monate",
                  "Monate",
                  "Monaten",
                  "Monate"
            ],
            "singular": [
                  "Monat",
                  "Monats",
                  "Monat",
                  "Monat"
            ]
      },
      "second": {
            "plural": [
                  "Sekunden",
                  "Sekunden",
                  "Sekunden",
                  "Sekunden"
            ],
            "singular": [
                  "Sekunde",
                  "Sekunde",
                  "Sekunde",
                  "Sekunde"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe",
                  "SuperSwipes",
                  "SuperSwipe",
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "Jahre",
                  "Jahre",
                  "Jahren",
                  "Jahre"
            ],
            "singular": [
                  "Jahr",
                  "Jahres",
                  "Jahr",
                  "Jahr"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
