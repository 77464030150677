/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Volver",
      "Descargar en la App Store",
      "Descargar en Google Play",
      "Carrusel",
      "Diapositiva {{number}}",
      "Siguiente diapositiva",
      "Diapositiva anterior",
      "Diapositiva",
      "Diapositiva {{number}} de {{total}}",
      "No vender ni compartir mi información personal",
      "No vender ni compartir mis datos personales",
      "Envía Compliments en Bumble para hacerle saber que te gusta su perfil y captar su interés antes de hacer match. Pruébalo ya",
      "Compliments | Cómo enviar un Compliment | Bumble",
      "Ten citas a tu ritmo y controla quién ve tu perfil de Bumble con Incógnito. Descubre cómo usar el modo Incógnito en Bumble en nuestra guía",
      "Modo Incógnito | Oculta tu perfil | Bumble",
      "Prueba una nueva forma de romper el hielo con Opening Moves de Bumble. Llama su atención, marca el tono y llega a la parte buena, más rápido",
      "Bumble Opening Moves | Bumble",
      "¿Necesitas un pequeño descanso o algo de tiempo para ti? Descubre cómo poner en pausa tu cuenta de Bumble con No molestar de Bumble. Para tener citas a tu aire",
      "No molestar | Pausa tu cuenta | Bumble",
      "¿Has visto a alguien que te gusta un montón en Bumble? Usa un SuperSwipe para demostrarle que vas en serio. Toca la estrella de su perfil para empezar, o consulta más detalles aquí",
      "Bumble SuperSwipe | ¿Qué es un SuperSwipe? | Bumble",
      "¿De viaje por trabajo o por placer? El modo Viajes de Bumble te ayudará a conocer gente nueva y divertida en cualquier lugar y a tener citas allá donde vayas. Descubre Viajes aquí ya mismo",
      "Modo Viajes de Bumble | Citas allá donde vayas | Bumble",
      "¿Quieres saber si esa conexión que sientes por mensajes implicará química en persona? Prueba ahora una videollamada o una llamada de voz en Bumble y haz tu test de buenas vibras",
      "Videollamada | Hacer una llamada | Bumble",
      "Vive la emoción de Bumble Web, la función definitiva.",
      "Bumble Web | Bumble",
      "Rescindir contratos aquí",
      "Página principal de Bumble",
      "Cerrar menú",
      "Abrir menú",
      "Selección de idioma",
      "Pie de página",
      "Accesibilidad en Bumble",
      "Apple y el logotipo de Apple son marcas comerciales de Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Empleo",
      "Eventos",
      "Google Play es una marca de Google LLC.",
      "Normas",
      "Inversores",
      "Gestionar cookies",
      "Gestionar cookies",
      "Declaración sobre la Modern Slavery Act",
      "Notificación de recopilación",
      "Política de privacidad",
      "Guía de citas por estaciones",
      "Condiciones de uso",
      "La app",
      "Política de privacidad de Washington Consumer Health",
      "Sobre",
      "Embajadores",
      "Blog",
      "Haz nuevas amistades y encuentra tu comunidad",
      "Crea contactos profesionales y avanza en tu carrera",
      "Encuentra una relación, algo casual, o lo que sea entre medias",
      "Descubrir más",
      "Globalízate con el modo Viajes",
      "Funciones",
      "Compliments",
      "Utiliza Compliments para que sepa por qué te gusta.",
      "Incógnito",
      "Utiliza el modo Incógnito para ocultar tu perfil a tu jefa, familiares o a tu ex.",
      "Opening Moves",
      "Elige Opening Moves para invitar a tu match a iniciar el chat, así no tendrás que hacerlo tú.",
      "No molestar",
      "Tómate un descanso con No molestar. Cuando te apetezca volver a las citas, ya sabes dónde estamos.",
      "SuperSwipe",
      "¿Su perfil te ha... cautivado? Házselo saber con un SuperSwipe.",
      "Viajes",
      "Encuentra tu capricho duty-free y haz match en cualquier parte del mundo con el modo Viajes.",
      "Videollamada",
      "Utiliza videollamadas y llamadas de audio para ver si os apetece conoceros en persona.",
      "App web",
      "Consigue la experiencia de Bumble directamente desde tu navegador.",
      "Tienda",
      "Principal",
      "Ir al contenido principal",
      "Logotipo de Bumble",
      "¿Quieres conocer a alguien? ¡Haz una videollamada en Bumble!",
      "Simplemente toca el icono de vídeo en tu chat para iniciar una videollamada, todo ello sin compartir ninguna información de contacto personal.",
      "¿Cómo funciona la videollamada en Bumble?",
      "No. Todas las llamadas y chats de Bumble garantizan la seguridad y protección de tus datos personales. No tendrá tu número ni ningún otro dato de contacto hasta que tú se lo digas.",
      "¿Mi match puede ver mi número de teléfono?",
      "¡Claro que sí! Si todavía no te atreves a un cara a cara, las llamadas de voz te permiten conectar a través de audio. Solo tienes que seleccionar el icono de llamada.",
      "¿Puedo probar solo con una llamada de audio?",
      "Nos encanta una buena notificación de *mensaje recibido*. Pero sabemos que es difícil prever si tus charlas van a ser igual de buenas en persona. Ahí es donde entran en juego las videollamadas y las llamadas de audio.",
      "Videollamada en Bumble",
      "<a href={{video_chat_url}}>Las videollamadas y llamadas de audio</a> te pueden ayudar a que os conozcáis mejor y decidir si quieres llevar las cosas más allá de la app. Lo mejor de todo es que cuando realizas una llamada a través de Bumble, no tienes que intercambiar números de teléfono, por lo que tu información personal permanece a salvo hasta que quieras compartirla.",
      "Comprueba vuestra química con una videollamada",
      "Acorta la distancia entre las citas online y en persona, y decide si ha llegado el momento de dar el salto y quedar.",
      "Conoceos mejor (y más rápido). Una conversación viéndoos las caras o por teléfono puede revelar mucho sobre alguien.",
      "Tú mandas. Mantendremos tus datos de contacto en privado, para que puedas elegir cuándo compartirlos.",
      "Ver si te apetece conoceros en persona",
      "Hacer un vibe check rápido",
      "Conectar sin facilitar información personal",
      "Motivos para utilizar las llamadas y videollamadas",
      "¿Todo listo para empezar una conversación? Puedes elegir entre iniciar una llamada de audio o una videollamada directamente desde el chat de la app de Bumble. Para realizar una videollamada, toca el icono gris de la cámara de vídeo en la esquina superior derecha. También verás tres puntos junto al icono, pulsa sobre ellos y verás la opción de realizar también una llamada de audio.",
      "Hacer una videollamada",
      "¿Te apetece probar Compliments?",
      "Cuando envíes un Compliment, la persona que lo recibe lo verá cuando aparezcas en su Para ti.",
      "¿Qué ocurre cuando alguien recibe un Compliment en Bumble?",
      "Si su Compliment te ha llamado la atención, adelante, haz match. Verás el Compliment en tu chat y se aplicarán las reglas habituales para <a href={{make_the_first_move_url}}>Dar el primer paso</a>.",
      "¿Cómo respondo a un Compliment?",
      "Puedes enviar {{num}} [[Compliments:num]] de forma gratuita. Cuando te quedes sin ellos, podrás comprar más en la app de Bumble en paquetes de hasta 30. Uno de ellos se utilizará para enviar ese Compliment (guarda el resto para cuando veas otro perfil que te guste). No te preocupes, ¡no caducan!",
      "¿Cuántos Compliments puedo enviar?",
      "Una palabra simpática (o dos) puede llevarte muy lejos. Si quieres enviar un Compliment pero te paralizas ante el teclado, inspírate con estos:",
      "Ver más ejemplos",
      "“Cualquiera que aprecie a [nombre de artista], tiene buen gusto.”",
      "\"¡Tu sonrisa es contagiosa!\"",
      "“Me parto con tu bío. Muy buena.”",
      "Envía mejores compliments en Bumble",
      "¿Quieres alegrarle el día? Entonces es hora de utilizar Compliments.",
      "Compliments en Bumble",
      "Utiliza Compliments en Bumble para hacer saber a tu posible match que te gusta su perfil (y tal vez sacarle los colores). Compliments forma parte de tu suscripción {{service_level_lexeme_name}}. También es una forma de enviar y recibir mensajes incluso antes de que hayáis hecho match.",
      "Mecanismo de la función Compliments",
      "Cuando recibas un Compliment, aparecerá en el perfil de quien te lo envía en tu Para ti. También recibirás una notificación indicándote que a alguien le gusta parte de tu perfil.",
      "Mira tus compliments",
      "Puedes enviar un Compliment pulsando el icono del corazón amarillo que aparece en la parte inferior del perfil de alguien. El lugar donde pulses es importante, porque estarás reaccionando a esa foto o parte específica de su perfil. Al hacer clic, se abrirá un cuadro de texto para que escribas tu mensaje (¡Puede tener hasta 150 caracteres!).",
      "Enviar un Compliment",
      "Descargar Bumble",
      "Preguntas frecuentes",
      "¿Te apetece explorar Bumble sin que te vean?",
      "Cuando actives Incógnito, no aparecerás ante otros miembros de Bumble a menos que te guste su perfil.",
      "¿Cómo funciona el modo Incógnito?",
      "Sin Incógnito, tu perfil es visible para otras personas en Bumble cuando encajas con sus preferencias, como la edad y la ubicación. Si activas el modo Incógnito, solo te verán las personas a las que hayas dado like.",
      "¿Quién puede verme en el modo Incógnito de Bumble?",
      "Usar el modo Incógnito no es lo mismo que <a href={{blocking_on_bumble_url}}>bloquear a alguien en Bumble</a>. Con esta función, si te gusta alguien (o si les has enviado un mensaje en el pasado), podrá ver tu perfil.",
      "¿Es Incógnito como bloquear a alguien?",
      "Controla quién ve tu perfil con Incógnito de Bumble.",
      "Incógnito en Bumble",
      "Si quieres dar un paso atrás en tus citas o navegar por Bumble sin que otras personas disponibles vean tu perfil, puedes activar la función Incógnito. Al hacerlo, ocultarás tu perfil a todo el mundo, a menos que antes le hubieras dado like. Esto te permite controlar lo que compartes y quién lo ve.",
      "Oculta tu perfil con Incógnito",
      "Tú decides quién puede ver tus fotos y tu información personal.",
      "¿Quieres incluir algo personal en tu perfil? Puedes compartir discretamente lo que quieras con tus matches actuales.",
      "Navega libremente sin preocuparte de encontrarte con tu ex, tu colega de trabajo o cualquier otra persona que no esté en tu radar de citas.",
      "Tienes el control total",
      "Eliges lo que compartes",
      "Evitas encuentros incómodos",
      "Motivos para amar el modo Incógnito",
      "El modo Incógnito está disponible para las personas usuarias de {{service_level_lexeme_name}} y {{service_level_lexeme_name_two}}. Una vez que te hayas registrado, puedes activarlo yendo a la pestaña de tu perfil, pulsando Ajustes en la esquina superior derecha y cambiando a Incógnito.",
      "Pasar a {{service_level_lexeme_name}}",
      "Usar Incógnito en Bumble",
      "Prueba Opening Moves en Bumble",
      "Los Opening Moves son otra forma de chatear con la gente que te gusta. Una vez elegida la pregunta o el mensaje que quieres enviar, puedes relajarte, esperar las respuestas y contestar a las que más te gusten.",
      "Usar los Opening Moves de Bumble",
      "Para conexiones no binarias y de mismo género, cualquiera de las personas puede establecer y responder a un Opening Move.",
      "¿Puedo usar Opening Moves de Bumble para salir con personas del mismo género?",
      "Cuando alguien responde a tu Opening Move, verás que su perfil aparece en tus chats. A las mujeres se les conceden 24 horas para responder antes de que el match caduque. A partir de ahí, puedes responder a su pregunta o iniciar una nueva conversación.",
      "¿Cómo puedo ver si alguien ha respondido a mis Opening Moves?",
      "Opening Moves es nuestra forma de quitar presión a la hora de dar el primer paso, con una manera más fácil y rápida de romper el hielo para llegar a una buena conversación.",
      "Opening Moves en Bumble",
      "Nos alegra que lo preguntes. Opening Moves es una forma nueva y más sencilla de iniciar una conversación. Puedes elegir un Opening Move sugerido como, por ejemplo, \"¿Qué canción eliges si te pasan la playlist compartida para una fiesta?\", o escribir el tuyo propio. Si tienes uno en tu perfil, tus matches pueden responder a tus preguntas cuando te vean aparecer en su pantalla de match.",
      "Descargar ahora",
      "¿Qué es un Opening Move?",
      "Quitarte algo de presión",
      "Compartir lo que te hace ser tú",
      "Tener mejores conversaciones",
      "Los Opening Moves pueden ayudar a...",
      "Puedes configurar un Opening Move para que se envíe cuando hagas match con otras personas. Solo tienes que ir a Opening Moves en tu perfil, elegir una de las preguntas preescritas o ser original y escribir la tuya propia. Puedes cambiar tus Opening Moves siempre que quieras.",
      "Ir a Opening Moves",
      "Forma de usar los Opening Moves de Bumble",
      "<a href={{see_more_url}}>Ver más</a>",
      "¿Quieres tomarte un descanso? Prueba No molestar",
      "Sí, puedes visitar tus ajustes o tu cola y desactivar No molestar cuando quieras.",
      "¿Puedo desactivar No molestar antes de tiempo?",
      "Ve a ajustes, pulsa No molestar y elige el tiempo que quieras estar ausente. Vuelve cuando sientas que quieres hacerlo.",
      "¿Cómo uso No molestar en mi perfil?",
      "No, mientras utilices No molestar, toda la actividad de deslizar y chatear estará en pausa. Cuando vayas a ver a gente nueva, te aparecerá un mensaje indicándote cuánto tiempo del modo No molestar te queda, con la opción de desactivarlo.",
      "¿Puedo seguir deslizando y chateando en el modo No molestar?",
      "Las personas a las que ya les has enviado un mensaje verán tu estado como \"Ausente\" (si decides configurarlo). De lo contrario, verán tus mensajes anteriores en su Bandeja de entrada como de costumbre.",
      "¿Qué verán los matches actuales cuando tenga activado No molestar?",
      "Tienes control total sobre tu actividad en Bumble, y puedes alejarte de la plataforma cuando quieras.",
      "Puedes mantener al día las conexiones existentes mediante tu actualización de estado, para que no parezca que estás haciendo <a href={{ghosted_article_url}}>ghosting</a>. Además, todo está automatizado, por lo que no tienes que enviar ningún mensaje.",
      "Tómate un descanso de la búsqueda activa de citas sin perder tus conexiones o tus conversaciones.",
      "Puedes tener citas (o desconectar) a tu manera",
      "Mantiene actualizadas tus conexiones",
      "Pausa tu actividad, pero no tus conexiones",
      "Razones para amar No molestar",
      "Abre la app de Bumble, visita la pestaña de tu perfil y pulsa el icono del engranaje en la esquina superior derecha. En tus Ajustes, toca en No molestar y elige cuánto tiempo te ausentarás. Puedes ocultar tu perfil durante 24 horas, 72 horas, una semana o indefinidamente.{{__EOL__}}{{__EOL__}}Recuerda que los matches sin chats activos caducarán mientras estés en el modo No molestar. Si decides establecer un estado, las personas con las que estés chateando en ese momento podrán ver que estás ausente.",
      "Mecanismo de No molestar en Bumble",
      "Cuando la vida se te hace bola, No molestar te ayuda. Pausa tu cuenta de Bumble y ten citas a tu aire.",
      "No molestar en Bumble",
      "No molestar es una forma fácil de pausar tu cuenta cuando deseas hacer un descanso del dating. Puedes seguir chateando con tus contactos actuales, pero oculta tu perfil a nuevas personas hasta que te apetezca volver a deslizar. Al activar el modo No molestar, no perderás tu información de perfil ni eliminarás las conexiones existentes.",
      "Pon tu perfil de Bumble en No molestar",
      "Mientras estás en modo No molestar, puedes elegir notificar a las conexiones existentes que estás descansando con una actualización de estado. Elige entre \"Estoy de viaje\", \"Tengo mucho trabajo\", \"Estoy de detox digital\" o \"Me estoy tomando un tiempo\". Sea cual sea tu energía, estamos aquí para ti.",
      "Añadir estado \"Ausente\"",
      "Activar No molestar pausa tu cuenta de Bumble (o {{page_partner_name_bff}}). Tus matches no sabrán que estás en modo No molestar a menos que se lo digas. Si decides salir del modo No molestar antes del tiempo que elegiste inicialmente, vuelve a Ajustes y toca \"No molestar\". Así de fácil.",
      "Pausa tus chats",
      "Prueba SuperSwipe en Bumble",
      "¡Tantos como quieras! Los usuarios de {{service_level_lexeme_name}} obtienen {{num}} [[superswipe:num]] por semana, pero puedes recargar tu saldo en cualquier momento. Los miembros gratuitos pueden comprar paquetes de SuperSwipes.",
      "¿Cuántos SuperSwipes puedo enviar en Bumble?",
      "Después de que alguien use un SuperSwipe en tu perfil, recibirás una notificación de inmediato. También verás una etiqueta encima del nombre de esa persona en su perfil, indicándote que le gustas.",
      "¿Cómo sé si alguien me envía un SuperSwipe?",
      "¿Sientes algo especial por alguien? Hay una forma genial de romper el hielo. Solo tienes que enviar un SuperSwipe para hacerle saber que te gusta y empezar conversaciones inolvidables, más rápido.",
      "SuperSwipe en Bumble",
      "Destacar entre la multitud no siempre es fácil, pero SuperSwipe puede ayudarte a causar una gran primera impresión.{{__EOL__}}{{__EOL__}}Cuando utilices SuperSwipe, se enviará una notificación a la persona que te ha gustado. A continuación, pasarás al primer puesto de su lista con una etiqueta en tu perfil. Si tú también le gustas, es hora de empezar a chatear.",
      "¿Qué es un SuperSwipe?",
      "Atreverte a destacar",
      "Iniciar más chats",
      "Crear más conexiones",
      "SuperSwipe puede ayudarte a…",
      {
            "__cfg": {
                  "lexem_id": 31183052,
                  "flex_vars_types": {
                        "num_three": "Num"
                  },
                  "values": {
                        "Default": "Si tienes <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> o Bumble {{service_level_lexeme_name_one}}, obtienes {{num}} [[superswipe:num]] gratis a la semana. Además, todos los miembros pueden comprar paquetes de hasta {{num_two}} [[superswipe:num_two]] desde la pestaña de perfil. Si tienes {{service_level_lexeme_name_three}}, obtendrás {{num_three}} [[superswipe:num_three]] semanales.{{__EOL__}}{{__EOL__}}Utilizar SuperSwipe es muy sencillo: cuando veas a alguien que te gusta, toca el icono de la estrella amarilla en la parte inferior de su perfil.",
                        "num_three=singular": "Si tienes <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> o Bumble {{service_level_lexeme_name_one}}, obtienes {{num}} [[superswipe:num]] gratis a la semana. Además, todos los miembros pueden comprar paquetes de hasta {{num_two}} [[superswipe:num_two]] desde la pestaña de perfil. Si tienes {{service_level_lexeme_name_three}}, obtendrás {{num_three}} [[superswipe:num_three]] semanal.{{__EOL__}}{{__EOL__}}Utilizar SuperSwipe es muy sencillo: cuando veas a alguien que te gusta, toca el icono de la estrella amarilla en la parte inferior de su perfil."
                  }
            }
      },
      "Así se usa SuperSwipe",
      "Apple y el logotipo de Apple son marcas comerciales de Apple Inc. Google Play es una marca de Google LLC.",
      "Planea citas donde vayas con el modo Viajes de Bumble",
      "<a href={{bumble_premium_url}}>los miembros {{service_level_lexeme_name}}</a> pueden usar el modo Viajes. Actívalo y en tu cola verás nuevas caras de la ciudad que elijas.",
      "¿Cómo uso el modo de Viajes?",
      "Prácticamente. Tenemos algunas restricciones de seguridad que pueden cambiar con el tiempo, pero el Viajes está disponible para la mayoría de los destinos.",
      "¿Puedo usar Viajes en cualquier lugar?",
      "Viajes mostrará tu ubicación como el centro de la ciudad elegida, para que las nuevas conexiones de la zona puedan encontrarte. Verán una nota en tu perfil indicando que estás en modo Viajes, para que sepan que estás de visita o planeas visitarla.",
      "¿Haciendo las maletas para tus vacaciones? ¿Te mudas por trabajo? Activa el modo Viajes de Bumble para llevarte las citas contigo.",
      "Modo Viajes en Bumble",
      "Viajes es tu herramienta definitiva para hacer conexiones mientras estás fuera. Establece tu ubicación en cualquier parte del mundo, y tu perfil aparecerá como si estuvieras en la ciudad que elijas. Esto significa que puedes formar parte del mundo de las citas allá donde tus aventuras te lleven.",
      "¿Qué es Viajes?",
      "Nadie conoce un lugar mejor que la gente de la zona. Viajes puede ayudarte a ponerte en contacto con las personas que harán que tu viaje sea aún más especial.",
      "Establece tu ubicación en la ciudad hasta siete días antes de viajar, para que puedas planificar citas y conocer matches con antelación.",
      "Piensa más allá de los viajes a la playa: puede que estés en casa por vacaciones, viajando por trabajo o explorando una nueva ciudad. Encuentra romanticismo allá donde aterrices.",
      "Encontrar los mejores sitios para salir",
      "Vivir una aventura",
      "Crear conexiones duraderas",
      "Razones para amar el modo Viajes",
      "El modo Viajes está disponible para miembros <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} y {{service_level_lexeme_name_two}}</a> de Bumble. Pulsa en el engranaje de Ajustes en la parte superior derecha de tu perfil y desplázate hasta que veas el modo Viajes. Elige tu nueva ubicación para empezar a conocer gente de esa zona. Tu perfil informará a tus posibles citas de que estás usando Viajes.",
      "¿Cómo funciona el modo Viajes?",
      "Probar ahora",
      "Prueba Bumble en la web",
      "Absolutamente. La aplicación web de Bumble funciona en PC, Mac y tabletas en todos los principales navegadores web.",
      "¿Puedo usar Bumble en mi PC o Mac?",
      "Sí, puedes Puedes enviar mensajes o incluso hacer una videollamada o una llamada de audio en la aplicación web de Bumble.",
      "¿Puedo chatear con matches en la aplicación web?",
      "Para iniciar sesión, selecciona \"Iniciar sesión\" o pulsa <a href={{sign_in_url}}>aquí</a>. Puedes iniciar sesión con tu ID de Apple, Facebook o número de teléfono.",
      "¿Cómo inicio sesión en Bumble en la web?",
      "¿Necesitas un descanso de tu pantalla de teléfono? Prueba la aplicación web de Bumble: la misma experiencia Bumble que adoras, en una pantalla más grande.",
      "Usa Bumble en la web",
      "Iniciar sesión en Bumble en la web es muy sencillo. Solo tienes que visitar nuestro sitio web, hacer clic en \"Iniciar sesión\" y elegir tu método de inicio de sesión habitual con tu ID de Apple, tus datos de Facebook o tu número de teléfono. ¡Listo! Ya estás dentro, y tus chats y conexiones están justo donde los dejaste.",
      "Entrar",
      "Inicia sesión a través de tu navegador",
      "Edita o realiza cambios en tu perfil y aplica Filtros avanzados.",
      "Confirma que eres tú para aumentar la probabilidad de hacer un match ideal.",
      "Comparte nuevas fotos cuquis o elimina las antiguas.",
      "Actualizar tu perfil",
      "Hacer que la gente sepa que eres tú de verdad",
      "Añadir o quitar fotos",
      "Lo que puedes hacer en Bumble en la web",
      "Encontrarás tus chats y lista de conexiones a tu izquierda, y posibles nuevos matches a tu derecha.{{__EOL__}}{{__EOL__}}Utiliza las teclas de flecha izquierda y derecha, o haz clic en los símbolos de la parte derecha de la pantalla para ver los perfiles y dar likes, como harías en tu teléfono.",
      "¿Cómo utilizo Bumble en la web?",
      "Aquí, querer bien es sexy, ser tal como eres es perfecto, y las mujeres son lo primero. Siempre.",
      "El santuario de Dar el primer paso™",
      "Las relaciones sanas son fundamentales para llevar una vida positiva y productiva. Bumble está diseñada para ayudarte a que te empoderes mientras creas nuevas conexiones, tanto si quieres salir con alguien, hacer nuevas amistades o ampliar tu red de contactos profesionales.",
      "Qué hace a Bumble diferente",
      "Defendemos la integridad, la amabilidad, la igualdad, la confianza y el respeto durante todas las etapas de cualquier relación. No solo acogemos, sino que celebramos todas las orientaciones, ya estés aquí para encontrar el amor o simplemente para divertirte.",
      "Los valores de Bumble",
      "En Bumble, las mujeres marcan el ritmo dando el Primer paso o proponiendo un Opening Move para que sus matches respondan. Así se cambian las viejas dinámicas de poder y se fomenta la igualdad desde el principio.",
      "Cómo funciona Bumble",
      "Conócenos",
      "Descargar ahora",
      "Es mejor en la app.",
      "Funciones diseñadas para que las citas online sean realmente, ya sabes, placenteras",
      "12 de febrero, 2024",
      "Cómo incrementar la intimidad emocional en tu relación de pareja",
      "25 de enero, 2024",
      "Cómo utilizar Bumble cuando nunca antes has usado una app de citas",
      "5 de febrero, 2024",
      "Deception Detector™",
      "Todas las novedades sobre el mundo de las citas",
      "Echa un vistazo a <b>Para ti</b> y descubre perfiles escogidos según tus preferencias y matches previos.",
      "Seleccionados para ti",
      "Tómate un descanso con <b>No molestar</b>. Cuando te apetezca volver a las citas, ya sabes dónde estamos.",
      "Haz una pausa",
      "Utiliza <b>videollamadas</b> y <b>chats de audio</b> para una experiencia lo más real posible sin salir de casa.",
      "Díselo fuerte",
      "Ver más",
      "Elige <b>Opening Moves</b> para invitar a tu match a iniciar el chat, así no tendrás que hacerlo tú.",
      "Menos esfuerzo, más citas",
      "Encuentra tu capricho duty-free y haz match en cualquier parte del mundo con el modo <b>Viajes</b>.",
      "Llévanos lejos",
      "¿Su perfil te ha... cautivado? Házselo saber con un <b>SuperSwipe</b>.",
      "Llama su atención",
      "Utiliza <b>Compliments</b> para que sepa por qué te gusta.",
      "Sácale los colores",
      "Utiliza el modo <b>Incógnito</b> para ocultar tu perfil a tu jefa, familiares o a tu ex.",
      "Perfil encubierto",
      "Ten las citas que tú quieres",
      "Unos meses después, también conoció allí a una de sus damas de honor",
      "Hannah conoció a su ahora marido en Bumble.",
      "Antes de construir su casita juntos, Carène y You-Liang se conocieron en Bumble, donde las fotos de viajes de Carène llamaron la atención de You-Liang.",
      "Carène y You-Liang",
      "Brian y Kaci compartieron destino en el extranjero, pero se conocieron en Bumble",
      "Brian y Kaci",
      "Si funcionó en su caso, podría funcionarte a ti.",
      "Da el siguiente paso",
      "Ver las novedades",
      "Mereces lo mejor, así que hemos diseñado formas estupendas para que tengas más citas y te estreses menos.",
      "Vuelve al sitio al que perteneces",
      "Sobre Bumble",
      "Somos la única app que mejora las citas dando prioridad a las experiencias de las mujeres. Porque cuando las cosas mejoran para las mujeres, mejoran para todo el mundo.",
      "Make the first move™",
      "Encuentra a alguien con quien valga la pena salir y Da el primer paso. Haz conexiones auténticas, vibra con lo que os apasiona y, lo más importante, diviértete.",
      "Conoce a alguien con Bumble Date",
      "Amplía tu círculo con beneficios laborales. Conecta con profesionales para encontrar tu próximo trabajo, una mentoría o incluso tu nueva profesión.",
      "Avanza en tu carrera con Bumble Bizz",
      "Conoce a alguien como tú. O diferente. Haz nuevas amistades con las que descubrir cosas nuevas que hacer, en tu ciudad o fuera.",
      "Haz amistades nuevas en Bumble For Friends",
      "Te damos mucho más que citas",
      "Bumble es la app de citas donde las mujeres dan el primer paso. Es la app que ha revolucionado la forma de tener citas, crea relaciones significativas y networking. Descárgala ahora.",
      "Bumble en {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Lo sentimos, pero no hemos podido encontrar la página que estabas buscando.",
      "Contacto",
      "Entrar",
      "Vaya, se ha producido un error",
      "(se abre en otra ventana)",
      "Preguntas",
      "Política de cookies",
      "Premium",
      "Bumble - El Blog",
      "Empleo",
      "Chat",
      "Ciudades",
      "Gestionar cookies",
      "Copyright © 2006 - presente. Bumble. Todos los derechos reservados.",
      "Informe sobre la confianza en las citas",
      "Apple y el logotipo de Apple son marcas comerciales de Apple Inc.{{__EOL__}}Google Play es una marca de Google LLC.",
      "Descargar",
      "Facebook",
      "Síguenos",
      "Centro de ayuda",
      "Instagram",
      "Idioma",
      "LinkedIn",
      "Bumble forma parte de Bumble Inc.",
      "Política de Privacidad",
      "Safety Centre",
      "Mapa web",
      "Declaración de Modern Slavery Act",
      "Condiciones de uso",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "días"
            ],
            "singular": [
                  "día"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mes"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "años"
            ],
            "singular": [
                  "año"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
